import React, { useState, useRef, useEffect, useCallback } from "react";

import { v4 as uuid } from "uuid";

import GrantPermissions from "./GrantPermissions";
import LocationQuestion from "./LocationQuestion"
import RecordingInterface from "./RecordingInterface";
import ExploreAll from "../Explore/ExploreAll";
import RecordProvider from "../../context/RecordProvider";
import canvasConfetti from "canvas-confetti";

import blobLoader01 from "./../../images/landing_blob-1.svg"
import blobLoader02 from "./../../images/landing_blob-2.svg"
import blobLoader03 from "./../../images/landing_blob-3.svg"
import blobLoader04 from "./../../images/landing_blob-4.svg"


const RecordPage = (props) => {

  const { projectId, analysisEndpoint, huggingFace } = props; 

  const [hasClickedStart, setHasClickedStart] = useState(true);

  const [hasMicPermission, setHasMicPermission] = useState(false);

  const [locationAnswer, setLocationAnswer] = useState(null);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [processingUpload, setProcessingUpload] = useState(false);
  const [processingResponse, setProcessingResponse] = useState(null);

  const [recordingResponse, setRecordingResponse] = useState(null);

  const pollIntervalTimer = useRef();
  let pollTimes = 0;
  let pollTimesMax = 120;

  const handleClickNext = () => {
    setHasClickedStart(true);
  }

  const handleSetPermissions = (mic) => {
    setHasMicPermission(mic);
  }

  const handleSetLocationAnswer = (answer) => {
    setLocationAnswer(answer);
  }

  const handleUpload = (blob) => {
    setHasSubmitted(true);
    setProcessingUpload(true);

    const formData = new FormData();
    let key = uuid();
    formData.append("project_id", projectId);
    formData.append("key", key);
    formData.append("location", locationAnswer.place_name);
    formData.append("latitude", locationAnswer.center[1]);
    formData.append("longitude", locationAnswer.center[0]);
    formData.append("sound_recording", blob, key + ".wav");

    fetch("/api/recordings", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        processUpload(result);
      })
      .catch((error) => {
        alert("Error on upload" + error);
        setProcessingResponse({status: "failure"});
        setProcessingUpload(false);
      });
  };

  const processUpload = (recording) => {
    fetch(`${analysisEndpoint}/process_audio`, {
      method: "POST",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer 0VnnrGxTwQG2PPut4DnltnpceQ8r5lYUuFmagGGKZejorASatEsZJEM2flxnXuXAKgOaFMWPMkH3HLtzMewYqOYXS7LhP8XKVFt2aEcxUD2liujYYuv5CFmDmwknDDVl',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        audio_url: recording.audio_url
      }),
    })
    .then(response => {
      if (!response.ok) {
        setProcessingResponse({status: "failure"});
        setProcessingUpload(false);
      }
      
      return response.json();
    })
    .then(responseData => {
      pollIntervalTimer.current = setInterval(() => pollTask(responseData.id, recording), 2000);

    })
    .catch((error) => {
      console.error(error);
      setProcessingResponse({status: "failure"});
        setProcessingUpload(false);
    });
  }

  const pollTask = (taskId, recording) => {
    pollTimes++;
    if(pollTimes > pollTimesMax) return handleTaskData({state: "TIMEOUT"})
    fetch(`${analysisEndpoint}/tasks/${taskId}`)
      .then((response) => {
        return response.json()
      })
      .then((responseData) => {
        handleTaskData(responseData, recording);
      })
      .catch((error) => {
        handleTaskData({state: "FAILURE"})
      });
  }

  const handleTaskData = (responseData, recording = {}) => {
    if(responseData.state === "TIMEOUT") {
      clearInterval(pollIntervalTimer.current);
      setProcessingResponse({status: "timeout"});
    } else if(responseData.state === "PENDING") {
    } else if (responseData.state === "FAILURE") {
      setProcessingResponse({status: "failure"});
      clearInterval(pollIntervalTimer.current);
    } else if (responseData.state === "SUCCESS") {
      clearInterval(pollIntervalTimer.current);
      fireConfetti();
      setProcessingUpload(false);

      setProcessingResponse({status: "ok", response: {...responseData.result, location: locationAnswer.place_name}});
      addAnalysisToRecording(JSON.parse(recording.data).id, responseData.result);
    }
  }

  const addAnalysisToRecording = (recordingId, data) => {
    let analysisData = JSON.stringify(data);
    fetch(`/api/recordings/${recordingId}/recording_analysis`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json', // Set the Content-Type header
      },
      body: JSON.stringify({ "recording_analysis": {"analysis": analysisData} }),
    })
      .then((response) => response.json())
      .then((result) => {
      })
      .catch((error) => {
        setProcessingResponse({status: "failure"});
        //plausible.trackEvent('upload_fail');
      });
  }

  const ProcessingUpload = () => {
    return (
      <div id="processing-container">
        <div className="d-flex flex-column page-content">
          <div>
            <p>Thank you!</p>
            <h1>Your recording is being processed by our friendly algorithms</h1>
            <p>This may take a while, depending on how long your recording is. We'll send it to an algorithm which will transcribe your recording, and code it with themes and emotions, based on what you said, and how you said it. This will be sent back to us, and we will transform this data into a little creature who plays a sound.</p>
            <div className="loading-blobs">
                <img src={blobLoader01} style={{ animationDelay: "0s"}}/>
                <img src={blobLoader02} style={{ animationDelay: "0.5s"}}/>
                <img src={blobLoader03} style={{ animationDelay: "1s"}}/>
                <img src={blobLoader04} style={{ animationDelay: "1.5s"}}/>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SubmissionReady = () => {

    let status = processingResponse.status;

    if (status === "timeout" || status === "failure") {
      return (
        <div id="submission-container">
          <div className="d-flex flex-column page-content">
            <h1>Oh no</h1>
            <p>Something went wrong when submitting your recording. We're sorry!</p>
            <p>If you're up for it, please <a href="/record" className="fw-bold">try again</a>. And if not, you can still explore other people's submissions!</p>
          </div>
        </div>
      )
    }

    if (status === "ok") {
      let theme = processingResponse.response.theme;
      let topEmotion = processingResponse.response.top_emotion?.name;

      return (
        <div id="submission-container">
          <div className="d-flex flex-column page-content">
            <h1>Your contribution is ready</h1>
            <>
              {theme && topEmotion && (
                <p>
                  Our algorithms detected that you talked about <span className="fw-bold">{theme}</span> and you seem to speak with some <span className="fw-bold">{topEmotion.toLowerCase()}</span>.
                </p>
              )}

              {theme && !topEmotion && (
                <p>
                  Our algorithms detected that you talked about <span className="fw-bold">{theme}</span>, but it couldn't really judge which emotions to attribute to your recording. 
                </p>
              )}

              {!theme && topEmotion && (
                <p>
                  Our algorithms could not really decide what theme to assign your recording to, but it thought that you seem to speak with some <span className="fw-bold">{topEmotion.toLowerCase()}</span>.
                </p>
              )}
              

              <p>We have transformed your recording into this creature, and if you click on it, it will play a melody based on what you sounded like</p>

              <div className="my-4 mx-auto w-100" style={{maxWidth: 250}}>
                <ExploreAll key={"single"} data={processingResponse.response} source="record" />
              </div>
            </>
          

            <div className="text-center my-4">
             <a href="/explore" className="btn btn-primary">
               Explore all recordings
             </a>
           </div>
          </div>
        </div>
      )
    }
  };

  return (
    <div className="f-container">

      {!hasSubmitted && (
        <>
          {hasClickedStart && !locationAnswer && !hasMicPermission && (
            <LocationQuestion setLocationAnswer={handleSetLocationAnswer} huggingFace={huggingFace}/>
          )}

          {hasClickedStart && locationAnswer !== null && !hasMicPermission && (
            <GrantPermissions
              setPermissions={handleSetPermissions}
              locationAnswer={locationAnswer}
            />
          )}

          {hasClickedStart && hasMicPermission && locationAnswer !== null && (
            <RecordingInterface
              locationAnswer={locationAnswer}
              upload={handleUpload}
            />
          )}
        </>
      )}

      {hasSubmitted && processingUpload && <ProcessingUpload />}

      {hasSubmitted && !processingUpload && <SubmissionReady />}
    </div>
  );
};

const fireConfetti = () => {
  var count = 200;
  var defaults = {
    origin: { y: 0.7 },
    colors: ["#f3cfc8", "#8C82BE", "#ffffff", "#6CAED4"],
  };

  const firePart = (particleRatio, opts) => {
    canvasConfetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      })
    );
  };

  firePart(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  firePart(0.2, {
    spread: 60,
  });
  firePart(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  firePart(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  firePart(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};


export default RecordPage;