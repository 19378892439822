// export const theme_shape_mapings = {
//   planet:
//     "m48.83,61.33c15.12,7.56,11.34,24.88,21.63,20.27h0c1.09-.43,2.28-.68,3.52-.68,5.27,0,9.54,4.27,9.54,9.54s-4.27,9.54-9.54,9.54l-35.6-.07c-.44.03-.87.07-1.32.07-.48,0-.95-.04-1.42-.07h-.32s0-.02,0-.02c-10.56-.88-18.86-9.72-18.86-20.5,0-11.37,9.22-20.59,20.59-20.59,4.8,0,9.4,1.1,11.76,2.51",
//   human:
//     "m69.13,47.34c-.64-10.02-8.95-17.96-19.13-17.96s-18.5,7.94-19.13,17.96c-.04.29-.06.58-.06.88,0,0-.45,32.59,0,44.78.14,3.85,3.15,7,7,7h24.39c3.85,0,6.97-3.15,7-7,.12-13.17,0-44.78,0-44.78,0-.3-.03-.59-.06-.88Z",
//   machine:
//     "m28.05,87.56s15.88-28.44,22.66-36.87c2.7-3.35,5.49-3.37,7.61.34,4.47,7.81,13,33.71,13,33.71,1.27,4.96,4.15,11.01,1.74,13.36-3.33,3.25-10.8,1.49-16.71.79-4.08-.48-10.2.45-16.43.77,0,0-9.92.82-12.55-1.12-2.21-1.63-1.55-6.56.68-10.99Z",
// };

export const theme_shape_mapping= {
    weather:
    "m27.18,77.82c-.08-.04-3.67,6.7-5.19,14.72-.5,2.63-1.02,5.5,0,6.02,1.52.79,5.9-3.96,8.7-8.53,4-6.54,4.72-12.65,5.19-12.55.59.12.96,10.27-.17,18.24-.11.75-.34,2.31.5,3.01,1.28,1.06,4.78-.07,6.69-2.34,1.77-2.1,1.88-5.34,2.01-11.71.06-2.72-.04-4.5.17-4.52.54-.05,3.25,11.87,4.02,15.23.6,2.66,1.01,3.96,2.47,4.43,1.55.51,4.02-.09,5.06-2.09,2.16-4.15,2.42-6.51,2.02-11.34-.11-1.33-1.03-4.85-.7-4.77,2.15.5,1.79,8.99,5.54,13.26,3.56,4.06,10.39,5.31,12.05,3.35,1.49-1.76-2.19-5.53-5.26-11.35-1.09-2.08-2.18-7.1-2.27-7.05-.15.08,4.22,13.1,10.88,13.55,1.89.13,4.38-.73,5.19-2.51,2.63-5.85-14.3-18.62-13.22-19.91.7-.84,7.03,5.34,15.06,4.35.61-.07,5.62-.73,6.02-2.84.86-4.49-19.99-11.35-19.24-13.72.45-1.43,8.38,1.34,20.86-.82,3.95-.68,5.08-2.38,5.24-3.2.43-2.2-7-6.58-14.89-7.53-6.47-.78-11.97.9-12.05.5-.1-.48,8.41-1.05,14.89-7.03,2.9-2.68,5.98-6.98,4.85-8.7-1.29-1.96-7.68.04-10.21.84-6.11,1.92-10.54,4.95-10.71,4.68-.21-.33,7.54-3.94,11.04-11.71.37-.81,2.41-5.34,1-6.53-2.32-1.95-12.86,6.16-15.73,8.37-3.56,2.74-6.36,5.3-6.86,4.85-.76-.68,5.64-6.36,5.52-14.22-.04-2.58-.8-6.84-2.84-7.36-2.99-.76-8.15,6.63-10.37,13.55-1.21,3.76-1.6,7.54-1.67,7.53-.11-.02,2.55-8.45,0-17.4-.56-1.96-2.35-8.25-5.69-8.53-2.49-.22-5.08,2.98-6.19,5.86-3.07,7.93,3.43,17.54,3.01,17.74-.47.22-4.84-13.64-12.05-14.39-3.04-.31-6.59,1.7-7.19,4.18-1.1,4.53,7.75,10.06,7.03,11.04-.7.96-8.19-5.56-14.06-3.18-1.7.69-3.79,2.33-3.68,4.02.28,4.19,14.08,6.2,13.89,7.19-.21,1.11-16.93-2.89-19.41,2.01-.39.77-.48,1.87,0,2.68,2.23,3.69,15.62-.77,15.73-.33.08.31-6.69,3.81-14.89,6.36-1.12.35-3.45,1.04-3.85,2.68-.28,1.15.42,2.66,1.51,3.35,3.49,2.21,11.51-2.91,11.99-2.26.31.42-3.41,1.51-7.56,4.1-4.71,2.95-7.65,3.82-8.12,6.02-.3,1.42.72,3.14,2.01,4.02,4.77,3.25,14.93-3.88,15.23-3.35.33.59-13.42,6.38-13.22,13.22.06,2.09,1.44,4.45,3.35,5.02,5.31,1.58,13.38-11.14,13.72-10.88.41.32-14.31,17.24-11.55,20.58,1.11,1.34,4.74.14,6.86-.84,2.31-1.07,3.5-3.84,4.62-5.64,2.67-4.29,4.98-7.39,4.92-7.42Z",
    nature:
    "m90.82,76.17c-.8-2.09-3.73-7.08-12.29-11.38-2.42-1.21-4.9-2.15-7.4-2.86,1.36-2.05,3.02-3.96,5.22-5.31,1.98-1.22,5.01-1.22,6.65-1.75,3.45-1.1,10.03-5.55,9.44-10.99-.59-5.44-7.99-8.28-11.59-8.7-4.17-.49-7.89.44-15.65,6.06-2.55,1.85-4.81,3.93-6.81,6.2,1.05-3.64,1.62-7.48,1.62-11.46,0-9.58-1.85-12.81-4.28-16.23-3.66-5.13-9.1-6.98-13.85-4.27-4.75,2.71-6.25,9.02-3.36,14.1.85,1.48,2.31,4.09,2.49,6.41.12,1.59-.05,3.14-.4,4.63-4.29-3-5.78-3.83-8.01-4.06-3.6-.38-11.44.77-13.21,5.95-1.77,5.18,1.27,10.91,6.8,12.8,1.62.55,4.43,1.54,6.1,3.16.49.48.94,1,1.35,1.54-1.95-.48-3.92-1.58-5.13-2.28-5.06-2.91-11.38-1.43-14.11,3.31-2.73,4.74.73,10.73,3.45,13.11,2.97,2.59,8.18,4.97,15.93,5.05-.14.03-.27.06-.42.09h0c-2.82.96-5.91,1.62-9.05,1.11-2.3-.37-4.77-2.04-6.18-3.01-4.81-3.31-11.23-2.34-14.33,2.17-3.1,4.51.25,11.92,3.1,14.16,3.73,2.92,6.27,4.82,15.81,5.62,6.53.55,39.99,1.51,46.37-.86,5.05-1.88,4.83-8.63,3.8-13.99.7.87,1.29,1.74,1.72,2.39,3.23,4.86,9.55,6.35,14.1,3.32,4.56-3.03,3.39-10.64,2.09-14.02Z",
    neighborhood:
    "m36.62,8.24c-12.36,1.09-18.43,11.38-22.47,18.87-5.14,9.54-7.18,19.94-8.03,35.73-.44,8.29-1.85,34.57,6.38,36.98,4.88,1.43,11.74-6.03,15.56-12.75,5.34-9.42-.78-17.82,2.19-18.94,2.37-.89,3.16,6.58,3.15,9.52-.05,11.09,3.34,17.89,6.74,21.22,2.87,2.82,12.06-1.06,15.27-6.29s3.34-3.65,4.19-14.49c.52-6.72,1.4-14.51,3.81-14.3,2.66.23-4.85,33.17,3.91,34.18,17.47,2.03,18.26-4.09,19.84-12.28,2.46-12.78,3.69-25.78,3.91-30,.43-8.18.91-17.36-4.85-24.74-9.43-12.1-32.81-24.19-49.59-22.72Z",
    innovation:
    "m4.71,97.45c2.37,2.75,5.48,3.18,40.46,1.75,21.44-.88,44.65-.71,46.4-1.37,7.5-2.83,3.45-9.78-4.03-41.87-4.23-18.16-9.08-35.54-11.37-35.54-8.22-.02-9.35,44.59-12.51,44.59-4.75,0-3.05-44.95-11.49-44.2-7.38.66-10.45,43.13-15.85,43.05-4.48-.07-4.74-40.45-12.67-40.45-3.02,0-7.6,6.22-18.17,50.12-2.6,10.79-5.19,18.81-.78,23.93Z",
    culture:
    "m78.91,58.05c1.97-1.87,6.38-2.29,9.64-6.36,2.03-2.55,5.51-8.45,5.65-11.93.7-17.93-10.46-24.67-22.86-24.67-7.8,0-17.66,13.73-21.68,19.77-4.02-9.75-18.29-18.48-26.1-18.48-12.4,0-21.29,8.68-21.29,21.09,0,3.08,1.92,9.89,3.13,12.53,3.36,7.33,11.23,8.15,14.46,8.6-4.85,2.18-14.97,5.85-14.75,17.71.12,6.17,2.01,12.57,6.04,16.63,4.07,4.1,11.54,6.05,17.77,6.05,11.49,0,17.07-7.82,21.09-13.86,4.02,6.04,10.76,13.86,18.56,13.86,7.18,0,17.39,1.77,24.62-6.84,3.12-3.71,3.86-10.91,3.86-16.13,0-10.92-9.4-16.53-18.13-17.97Z",
    community:
    "m74.95,14.8c-8.83-5.74-18.69-6.48-18.69-6.48-1.91-.14-10.58-.65-20.05,3.92-11.83,5.71-17.29,15.66-22.76,25.62-3.75,6.83-8.41,15.56-9.04,27.73-.34,6.51-.78,15.02,4.67,21.55,1.04,1.24,4.31,7.44,18.54,10.7,12.64,2.9,24.53,3.17,38.74-1.06,6.5-1.94,17.17-5.11,24.12-14.47,14.65-19.74,3.81-54.96-15.52-67.53Zm-7.5,47.9c-4.64,3.29-10.83,3.53-15.52,1.06-1.14-.6-5.11-2.69-6.48-7.39-1.32-4.52.71-8.28,1.06-8.89,1.86-3.3,4.69-5.31,6.84-6.27,2.66-1.19,7.7-2.71,12.91-.36.31.14,6.63,3.13,7.39,9.8.78,6.94-5.21,11.37-6.18,12.06Z",
    Unknown:
    "m62.18,10.71c-9.54-2.22-23.69,2.11-34.05,6.96-5.62,2.64-10.94,6.41-17.17,13.92-4.05,4.89-5.8,7.97-7.2,11.11-1.06,2.38-3.5,8.46-3.7,14.56-.37,11.02,1.06,21.31,5.08,26.28,1.93,2.39,5.14,6.31,15.66,10.9,6.3,2.75,18.89,2.75,27.79,2.61,15.24-.23,26.62-8.03,30.84-10.79,4.6-3.01,11.4-7.45,16-16.24.51-.97,5.19-12.35-.8-28.82-2.34-6.43-5.7-13.58-11.33-18.78-7.29-6.73-13.03-9.84-21.13-11.72Z",
  };

    
  export const theme_shape_mapping2={
    weather:
    "m26.66,77.67c-.08-.04-3.7,6.75-5.22,14.83-.5,2.65-1.02,5.54,0,6.07,1.53.79,5.94-3.99,8.76-8.59,4.03-6.59,4.76-12.74,5.22-12.64.59.13.96,10.35-.17,18.37-.11.76-.35,2.33.51,3.03,1.29,1.06,4.81-.07,6.74-2.36,1.79-2.12,1.89-5.37,2.02-11.8.06-2.74-.04-4.53.17-4.55.54-.06,3.28,11.96,4.04,15.33.61,2.67,1.02,3.98,2.48,4.47,1.56.51,4.05-.09,5.1-2.11,2.18-4.18,2.43-6.55,2.04-11.42-.11-1.34-1.03-4.88-.71-4.8,2.16.51,1.8,9.05,5.58,13.36,3.59,4.09,10.46,5.35,12.13,3.37,1.5-1.78-2.21-5.57-5.3-11.44-1.1-2.09-2.2-7.15-2.29-7.1-.15.08,3.61,13.53,10.31,13.98,1.9.13,4.41-.73,5.22-2.53,2.65-5.89-13.76-19.08-12.67-20.38.7-.84,6.57,7.13,14.66,6.14.61-.08,5.66-.74,6.07-2.86.87-4.53-19.62-13.18-18.87-15.57.45-1.44,8.45,7.22,21.02,5.04,3.98-.69,5.12-2.4,5.28-3.22.44-2.22-6.73-7.36-14.68-8.32-6.51-.79-12.39-4.23-12.47-4.63-.1-.48,9.18,1.74,15.71-4.27,2.92-2.7,6.03-7.03,4.89-8.76-1.3-1.98-7.73.04-10.28.84-6.16,1.93-11.32,2.18-11.49,1.92-.21-.34,9.72-.04,13.25-7.87.37-.82,2.43-5.38,1.01-6.57-2.33-1.96-13.85,3.56-16.74,5.79-3.58,2.75-7.63,4.05-8.14,3.6-.76-.68,10.45-3.51,10.34-11.44-.04-2.6-.81-6.89-2.86-7.41-3.01-.77-11.05,5.13-13.29,12.1-1.22,3.79-3.55,6.26-3.62,6.24-.11-.02-.04-7.84-2.61-16.86-.56-1.97-2.36-8.3-5.73-8.59-2.51-.22-5.11,3-6.23,5.9-3.1,7.99,6.06,17,5.64,17.19-.47.22-10.4-11.23-17.66-11.98-3.06-.32-6.64,1.71-7.25,4.21-1.1,4.56,13.33,7.62,12.61,8.61-.71.96-8.66-1.67-14.57.72-1.71.69-3.82,2.35-3.71,4.04.28,4.22,14.6,2.32,14.41,3.32-.21,1.12-16.42-.12-18.92,4.81-.39.78-.49,1.89,0,2.7,2.25,3.72,15.1-3.55,15.21-3.12.08.31-6.29,6.06-14.55,8.63-1.12.35-3.47,1.05-3.88,2.7-.29,1.16.43,2.68,1.52,3.37,3.51,2.23,11.15-5.16,11.63-4.5.31.42-2.74,3.55-6.91,6.17-4.74,2.97-7.7,4.95-8.17,7.17-.3,1.43.72,3.16,2.02,4.04,4.8,3.27,14.33-7.05,14.64-6.51.33.59-13.28,8.59-13.07,15.48.06,2.11,1.45,4.48,3.37,5.06,5.35,1.59,13.24-13.38,13.58-13.12.41.32-13.27,18.91-10.49,22.28,1.11,1.35,4.78.14,6.91-.84,2.32-1.07,2.39-5.42,3.51-7.22,2.69-4.33,5.02-7.44,4.96-7.47Z",
    nature:
    "m93.4,78.91c-2.6-5.98-6.83-9.96-15.39-14.26-2.42-1.21-4.9-2.15-7.4-2.86,1.36-2.05,2.9-3.24,5.36-4.03,2.6-.84,10.47.69,12.19.62,8.81-.4,10.84-6.69,10.25-12.13-.59-5.44-9.45-8.55-13.05-8.97-4.17-.49-12.92-1.8-20.68,3.82-2.55,1.85-4.81,3.93-6.81,6.2,1.05-3.64,1.62-7.48,1.62-11.46,0-9.58-2.02-12.89-4.45-16.31-3.66-5.13-11.98-4.77-16.73-2.06-4.75,2.71-6.25,9.02-3.36,14.1.85,1.48,3.09,2.95,4.23,5.16.73,1.41,1.26,2.25.91,3.74-4.29-3-11.99-4.01-16.93-3.69-3.66.23-12,3.46-13.78,8.63-1.77,5.18,1.03,9.84,6.47,11.97,4.11,1.61,14.23-.69,15.9.93.49.48.94,1,1.35,1.54-2.24-.08-3.77-.29-6.12-.72-5.75-1.04-11.38-1.43-14.11,3.31-2.73,4.74.73,10.73,3.45,13.11,2.97,2.59,5.35,4.5,13.1,4.58-.14.03-.27.06-.42.09h0c-2.82.96-3.37,1.1-5.36.84-2.42-.32-3.96-1.52-6.46-2.6-5.36-2.32-11.97-1.35-15.07,3.15-3.1,4.51.25,11.92,3.1,14.16,3.73,2.92,5.78,4.06,15.36,4.06,7.46,0,41.6.84,47.98-1.53,5.05-1.88,4.83-8.63,3.8-13.99.7.87,3.46,6.45,3.89,7.1,3.23,4.86,9.55,6.35,14.1,3.32,4.56-3.03,4.48-12.52,3.03-15.85Z",
    neighborhood:
    "m35.88,18.27c-12.39.66-20.19,7.46-24.22,14.95-5.14,9.54-4.68,13.83-5.53,29.62-.44,8.29-8.6,33.95-.38,36.37,4.88,1.43,18.49-5.41,22.31-12.13,5.34-9.42-.6-10.53,2.37-11.65,2.37-.89,2.98,3.89,2.97,6.84-.05,11.09,3.35,13.28,6.74,16.62,2.87,2.82,12.06-1.06,15.27-6.29s3.34-5.83,4.18-9.89c1.37-6.6,1.58-11.83,4-11.62,2.66.23,3.01,26.24,11.77,27.25,17.47,2.03,18.26-4.09,19.84-12.28,2.46-12.78-3.33-26.21-4.13-30.36-1.35-6.97-7.2-16.31-10.41-19.56-12.9-13.07-8.78-19.79-44.77-17.87Z",
    innovation:
    "m4.71,97.45c2.37,2.75,5.48,3.18,40.46,1.75,21.44-.88,44.65-.71,46.4-1.37,7.5-2.83,12.24-11.25-.72-41.55-6.91-16.16-22.92-32.41-25.22-32.42-8.22-.02,4.68,38.07,1.51,38.07-4.75,0-17.08-38.43-25.52-37.68-7.38.66,3.4,37.76-2,37.68-4.48-.07-18.77-33.38-26.52-35.08-6.84-1.5-5.51,15.92-5.08,46.59.16,11.1-7.74,18.89-3.33,24.01Z",
    culture:
    "m80.37,56.65c1.97-1.87,1.09-.94,4.35-5.01,2.03-2.55,3.69-7.13,3.82-10.6.7-17.93-8.43-21.33-20.84-21.33-7.8,0-14.35,5.18-18.37,11.22-4.02-9.75-12.67-10.35-20.47-10.35-12.4,0-21.29,8.68-21.29,21.09,0,3.08.79,9.42,1.69,12.17.95,2.92,4.31,7.26,7.54,7.71-4.85,2.18-8.5,3.75-8.27,15.62.12,6.17,2.26,11.72,6.29,15.78,4.07,4.1,7.87,6.04,14.1,6.04,11.49,0,17.07-2.59,21.09-8.63,4.23,4.07,10.02,6.29,17.83,6.29,7.18,0,11.73-1.29,18-6.9,3.61-3.23,4.72-9.7,4.44-14.92-.45-8.22-2.47-15.47-9.9-18.17Z",
    community:
    "m75.51,20.89c-8.83-5.74-18.69-6.48-18.69-6.48-1.91-.14-10.58-.65-20.05,3.92-11.83,5.71-19.79,13.16-25.26,23.12-3.75,6.83-6.48,11.97-7.11,24.14-.34,6.51,1.56,14.18,7.01,20.72,1.04,1.24,4.31,7.44,18.54,10.7,12.64,2.9,20.81,1.99,35.01-2.24,6.5-1.94,17.17-5.11,24.12-14.47,14.65-19.74,5.77-46.85-13.57-59.41Zm-8.66,40.63c-4.64,3.29-7.91,2.99-11.95,1.71-1.23-.39-5.01-1.68-6.38-6.38-1.32-4.52.12-7.57.4-8.6.99-3.66,3.81-5.78,5.47-6.48,2.68-1.13,5.65-2.21,10.85.15.31.14,5.43,1.82,6.18,8.49.78,6.94-3.6,10.42-4.57,11.1Z",
    Unknown:
    "m56.23,13.25c-4.62-1.22-15.17-4-25.52.85-5.62,2.64-10.35,5.67-18.57,15.88-3.98,4.95-5.81,9.73-7.22,12.87-1.06,2.38-2.7,7.77-2.9,13.87-.37,11.02,6.24,19.2,10.26,24.18,1.93,2.39,7.93,9.38,18.32,14.29,3.04,1.44,8.51,3.96,16,4.64,15.18,1.38,26.68-5.91,30.89-8.67,4.6-3.01,11.4-7.45,16-16.24.51-.97,9.17-18.14,1.1-33.7-3.02-5.82-7.75-9.3-17.22-16.24-8-5.87-13.09-9.6-21.13-11.72Z",
  }
  

export const emotion_color_mappings = {
  Admiration: "#855C4A",
  Adoration: "#855C4A",
  "Aesthetic Appreciation": "#DF6C50",
  Amusement: "#8A83BA",
  Anger: "#D5AD88",
  Anxiety: "#EFC2B0",
  Awe: "#687754",
  Awkwardness: "#EFC2B0",
  Boredom: "#DF6C50",
  Calmness: "#DF6C50",
  Concentration: "#687754",
  Confusion: "#8A83BA",
  Contemplation: "#8A83BA",
  Contempt: "#7AACD0",
  Contentment: "#EFC2B0",
  Craving: "#DF6C50",
  Desire: "#855C4A",
  Determination: "#EFC2B0",
  Disappointment: "#687754",
  Disgust: "#EFC2B0",
  Distress: "#DF6C50",
  Doubt: "#8A83BA",
  Ecstasy: "#855C4A",
  Embarrassment: "#855C4A",
  "Empathic Pain": "#855C4A",
  Entrancement: "#7AACD0",
  Envy: "#7AACD0",
  Excitement: "#D5AD88",
  Fear: "#687754",
  Guilt: "#DF6C50",
  Horror: "#DF6C50",
  Interest: "#7AACD0",
  Joy: "#D5AD88",
  Love: "#8A83BA",
  Nostalgia: "#855C4A",
  Pain: "#687754",
  Pride: "#D5AD88",
  Realization: "#D5AD88",
  Relief: "#855C4A",
  Romance: "#855C4A",
  Sadness: "#855C4A",
  Satisfaction: "#7AACD0",
  Shame: "#8A83BA",
  "Surprise (negative)": "#687754",
  "Surprise (positive)": "#855C4A",
  Sympathy: "#D5AD88",
  Tiredness: "#EFC2B0",
  Triumph: "#D5AD88",
  Unknown: "#FFFFFF"
};

export const notes = {
  Love: "4",
  Contemplation: "1",
  Sadness: "2",
  Calmness: "3",
  Admiration: "4",
  Adoration: "5",
  "Aesthetic Appreciation": "6",
  Amusement: "7",
  Satisfaction: "2",
  Anger: "8",
  Anxiety: "9",
  Awe: "10",
  Awkwardness: "11",
  Boredom: "12",
  Concentration: "13",
  Confusion: "14",
  Contempt: "15",
  Contentment: "16",
  Craving: "17",
  Desire: "18",
  Determination: "19",
  Disappointment: "20",
  Disgust: "21",
  Distress: "22",
  Doubt: "23",
  Ecstasy: "24",
  Embarrassment: "25",
  "Empathic Pain": "26",
  Entrancement: "27",
  Envy: "28",
  Excitement: "21",
  Fear: "22",
  Guilt: "23",
  Horror: "24",
  Interest: "25",
  Joy: "26",
  Nostalgia: "27",
};

export const theme_color_mappings = {
  weather: "#DF6C50",
  nature: "#EFC2B0",
  community: "#8A83BA",
  innovation: "#7AACD0",
  neighborhood: "#687754",
  culture: "#855C4A"
}

export const mapNumRange = (num, inMin, inMax, outMin, outMax) =>
((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

export const locationVariations = ["I came", "I traveled here", "Someone sent me", "I bounced here", "I just rolled in", "I wobbled my way here" ]