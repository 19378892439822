import React, { useRef, useState, useEffect, useCallback } from "react";

import RecordRTC from "recordrtc";
import { createNoise2D } from "simplex-noise";
import { WaveSurfer, WaveForm } from "wavesurfer-react";
import { spline } from "@georgedoescode/spline";

import { createPoints, noise, map } from "../../utils/svg";

function RecordingInterface(props) {
  const { locationAnswer, upload } = props;

  const recordingMaxTime = 30 * 1000; // First number is the time in seconds
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [blobAvgAmp, setBlobAvgAmp] = useState(null);

  // recordrtc
  const [stream, setStream] = useState(null);
  const [blob, setBlob] = useState(null);
  const recorderRef = useRef(null);

  // wavesurfer
  const wavesurferRef = useRef();

  // svg blob
  // Blob
  const pathRef = useRef(null);
  const noise2D = useRef(null);
  const [points, setPoints] = useState(null);
  const requestRef = useRef();
  const previousTimeRef = useRef();

  // Blob
  let hueNoiseOffset = 0;
  let noiseStep = 0.01;
  let variation = 10;

  let blobFirst =
    "m64.72,35.4c-4.77-2.02-9.76-2.56-14.39-2.42-8.79.27-14.95,6.35-19.48,10.82-4.44,4.38-11.89,11.73-12.68,23.19-.25,3.62-.8,11.54,4.17,16.85,3.51,3.75,4.64,5.09,33.24,4.64,3.7-.06,9.28-.12,19.48-.15,5.77-.02,8.6-1.02,10.36-2.47,7.77-6.42,4.56-28.43-7.11-40.04-5.39-5.36-11.02-9.33-13.6-10.42Z";
  let blobSecond =
    "m63.99,10.8c-4.77-2.02-9.76-2.56-14.39-2.42-8.79.27-15.96,5.52-19.48,10.82-3.81,5.73-10.38,36.33-11.17,47.79-.25,3.62-.8,11.54,4.17,16.85,3.51,3.75,7.78,4.73,32.47,4.65,3.7-.01,5.01.04,15.2,0,5.77-.02,8.6-1.02,10.36-2.47,7.77-6.42,8.1-53.2-3.57-64.81-5.39-5.36-11.02-9.33-13.6-10.42Z";

  useEffect(() => {
    // svg blob
    noise2D.current = createNoise2D();
    setPoints(createPoints(8, 100, 100, 85));

    //plausible.trackPageview();
  }, []);

  const handleWSMount = useCallback((waveSurfer) => {
    wavesurferRef.current = waveSurfer;
    if (wavesurferRef.current) {
      let url = URL.createObjectURL(recorderRef.current.getBlob());
      wavesurferRef.current.load(url);
      wavesurferRef.current.on("seek", function () {
        wavesurferRef.current.play();
      });
    }
  }, []);

  useEffect(() => {
    if (points !== null) pathRef.current = spline(points, 1, true);
  }, [points]);

  useEffect(() => {
    if (!isRecording) {
      return setRecordingTime(0);
    } else requestRef.current = requestAnimationFrame(animate);

    const interval = setInterval(
      () => setRecordingTime((recordingTime) => (recordingTime += 1)),
      1000
    );

    return () => {
      clearInterval(interval);
      cancelAnimationFrame(requestRef.current);
    };
  }, [isRecording]);

  const startRecording = async () => {
    if (isRecording) return;
    setIsRecording(true);
    document.getElementById("permissions-blob").classList.add("hide");
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: false,
        autoGainControl: true,
      },
    });

    setStream(mediaStream);
    recorderRef.current = new RecordRTC(mediaStream, {
      type: "audio",
      mimeType: "audio/wav",
      audioBitsPerSecond: 32000,
      recorderType: RecordRTC.StereoAudioRecorder,
      numberOfAudioChannels: 1,
    });
    mediaStream.getAudioTracks()[0].enabled = true; // unmute microphone
    // mediaStream.getAudioTracks()[0].start()
    recorderRef.current
      .setRecordingDuration(recordingMaxTime)
      .onRecordingStopped(function () {
        setBlob(recorderRef.current.getBlob());
        setIsRecording(false);
        mediaStream.getAudioTracks()[0].enabled = false; // mute microphone
        mediaStream.getAudioTracks()[0].stop();
      });
    recorderRef.current.startRecording();
    setTimeout(() => {
      stopRecording();
    }, [29999]);
  };

  const stopRecording = () => {
    setIsRecording(false);
    recorderRef.current.stopRecording(() => {
      let blob = recorderRef.current.getBlob();
      analyseBlob(blob).then((averageAmplitude) => {
        setBlobAvgAmp(averageAmplitude);
      });
      setBlob(blob);
    });
  };

  const analyseBlob = (blob) => {
    return new Promise((resolve, reject) => {
      blob.arrayBuffer().then((audioBuffer) => {
        const audioContext = new AudioContext();
        audioContext.decodeAudioData(audioBuffer).then((decodedAudioBuffer) => {
          // Analyze the audio buffer
          const audioData = decodedAudioBuffer.getChannelData(0); // Assuming mono audio
          const audioLength = audioData.length;

          // Calculate the average amplitude
          let totalAmplitude = 0;
          for (let i = 0; i < audioLength; i++) {
            totalAmplitude += Math.abs(audioData[i]);
          }
          const averageAmplitude = totalAmplitude / audioLength;

          resolve(averageAmplitude);
        });
      });
    });
  };

  const animate = (time) => {
    if (pathRef.current === null || points === null) return;
    if (previousTimeRef.current !== undefined) {
      pathRef.current.setAttribute("d", spline(points, 1, true));

      // for every point...
      for (let i = 0; i < points.length; i++) {
        const point = points[i];
        setNewCoords(noise2D.current, point, variation, noiseStep);
      }

      hueNoiseOffset += noiseStep / 6;
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  const clickUpload = () => {
    upload(blob);
    setBlob(null);
  };

  return (
    <div id="recorder-container" className="page-content">
      {!blob && (
        <div>
          <h1>Think about...</h1>
          <p>
            A place that you love – your neighborhood, a place you feel
            connected to nature, or somewhere else that is special to you.
          </p>
          <h1>
            Now, tell us something you cherish about this place, 
            that you want to protect from an uncertain climate future.
          </h1>
        </div>
      )}
      {blob && blobAvgAmp === 0 && (
        <div>
          <h1>We could not hear you!</h1>
          <p>
            Something seems to have gone wrong with the recording, so we didn't
            get any audio data. Please try again!
          </p>
          <div className="recording-buttons--has-recording">
            <button
              className="btn btn-outline-primary recording-button plausible-event-name=Clicked+StartOver"
              id="delete"
              title="Delete recording"
              onClick={() => {
                if (wavesurferRef.current) wavesurferRef.current.stop();
                setBlob(null);
                // plausible.trackEvent('upload_delete');
              }}
            >
              Delete recording and start over
            </button>
          </div>
        </div>
      )}
      {blob && blobAvgAmp > 0 && (
        <div>
          <h1>Thank you for sharing!</h1>
          <p>
            If you're happy with your recording, you can upload it to our digital 
            collective of stories about what we cherish from around the world.
          </p>
        </div>
      )}
      <div className="recorder-display">
        {blob && blobAvgAmp > 0 && (
          <WaveSurfer onMount={handleWSMount}>
            <WaveForm
              id="waveform"
              hideCursor
              cursorColor="transparent"
              progressColor="#141414"
              waveColor="#647850"
              barWidth={8}
              barRadius={8}
              barGap={6}
              barHeight={1}
              height={100}
            ></WaveForm>
          </WaveSurfer>
        )}
        <div id="permissions-blob" className="left">
          <p>
            Don't worry, no human is going to <br />
            listen to your recording
          </p>
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
          >
            <path
              fill="#7AACD0"
              d="m24.21,13.42c3.12-4.43,10.94-10.79,25.27-10.88,19.63-.13,27.06,10.9,29.9,13.82,2.92,3.02,15.7,20.76,12.31,42.34-2.93,18.64-17.11,29.16-22.38,32.46-10.4,6.5-28.37,12.88-48.64.48-4.87-2.98-12.01-8.27-13.77-22.75-1.28-10.52,4.35-16.75,7.79-27.51,3.01-9.43,3.24-19.02,9.54-27.96Z"
            />
            <path
              fill="black"
              d="m63.72,25.33c4.34.27,6.32-1.06,6.89-.19.48.72-.4,2.39-1.58,3.16-2.19,1.43-5.13-.41-5.96-.93-.82-.51-2.06-1.49-1.86-1.95.16-.38,1.18-.18,2.51-.09Z"
            />
            <ellipse fill="black" cx="61.62" cy="20.7" rx="1.15" ry="1.82" />
            <ellipse fill="black" cx="69.65" cy="20.7" rx="1.15" ry="1.82" />
          </svg>
        </div>
      </div>

      <div className="recording-button-container d-flex justify-content-center my-4">
        {blob && blobAvgAmp > 0 && (
          <div className="recording-buttons--has-recording">
            <button
              className="btn btn-outline-primary recording-button plausible-event-name=Clicked+DeleteRecording"
              id="delete"
              title="Delete recording"
              onClick={() => {
                if (wavesurferRef.current) wavesurferRef.current.stop();
                setBlob(null);
                // plausible.trackEvent('upload_delete');
              }}
            >
              Delete recording and start over
            </button>
            <button
              className="btn btn-secondary recording-button"
              id="play"
              title="Play recording"
              onClick={() => wavesurferRef.current.play(0)}
            >
              Play my recording
            </button>
            <button
              className="btn btn-primary recording-button plausible-event-name=Clicked+UploadRecording"
              id="upload"
              title="Upload recording"
              onClick={clickUpload}
            >
              Upload
            </button>

            <p className="mt-4 fw-small">
              By clicking "Upload", you agree to our{" "}
              <a target="_blank" href="/terms_and_conditions">
                Terms and Conditions
              </a>
            </p>

            <p className="mt-4 fw-small">
              * We'll send it to a friendly algorithm which will transcribe your 
              recording and code it with themes and emotions based on what you said and how you said it. 
              This will be sent back to us to transform this data into a little digital being with a melody.
            </p>
          </div>
        )}
        {!blob && (
          <div className="recording-buttons">
            {!isRecording && (
              <button
                className={
                  isRecording
                    ? "btn btn-secondary recording-button recording-interface-button is-recording"
                    : "btn btn-secondary recording-button recording-interface-button"
                }
                id="start"
                title="Start recording"
                onClick={() => startRecording()}
                // onTouchStart={() => startRecording()}
              >
                <span className="fw-bold">Start</span> recording
              </button>
            )}

            {isRecording && (
              <button
                className={
                  isRecording
                    ? "btn btn-secondary recording-button recording-interface-button is-recording"
                    : "btn btn-secondary recording-button recording-interface-button"
                }
                id="start"
                title="Start recording"
                onClick={() => stopRecording()}
                // onTouchStart={() => stopRecording()}
              >
                <span className="fw-bold">Stop</span> recording
              </button>
            )}
          </div>
        )}
      </div>

      {isRecording && (
        <div className="is-recording-container">
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            width="100%"
          >
            <path fill="#DF6C50" d={blobFirst}>
              <animate
                attributeName="d"
                values={blobFirst + ";" + blobSecond + ";" + blobFirst}
                dur="2s"
                repeatCount="indefinite"
              />
            </path>
            <path
              className="blob-face"
              d="m46.38,36.38c4.19,2.36,9.63,2.11,13.54-.71.73-.53.03-1.76-.72-1.22-3.51,2.53-8.33,2.84-12.11.71-.8-.45-1.51.78-.72,1.22h0Z"
            />
            <path
              className="blob-face"
              d="m45.7,31.57c.3.5.63.98,1.11,1.33s1.06.47,1.62.44c1.2-.06,2.34-.71,2.81-1.83.14-.34-.15-.79-.49-.87-.41-.09-.72.13-.87.49.04-.09,0,0-.03.03-.02.04-.04.07-.07.11-.06.09.11-.11-.03.03-.03.03-.06.06-.09.09s-.06.06-.1.09c-.15.13.06-.04-.04.03-.08.06-.16.11-.25.16-.04.02-.08.04-.12.06-.11.06.03,0-.04.02-.09.04-.19.07-.28.1s-.19.04-.29.06c-.11.02.1,0,0,0-.05,0-.11,0-.17,0-.09,0-.18,0-.26,0,.13,0,0,0-.04,0-.04,0-.08-.02-.13-.03-.05-.01-.1-.03-.15-.05.07.02.04.02,0,0-.05-.03-.1-.05-.14-.08-.01,0-.12-.08-.06-.04.06.04-.07-.06-.08-.07-.03-.03-.06-.06-.09-.09-.04-.04-.07-.08-.11-.13-.07-.09.05.08-.01-.02-.13-.18-.24-.36-.35-.54-.19-.32-.64-.46-.97-.25s-.46.63-.25.97h0Z"
            />
            <path
              className="blob-face"
              d="m53.66,31.31c.79,1.04,2,1.6,3.31,1.41,1.23-.18,2.44-1.07,2.61-2.35.05-.37-.36-.72-.71-.71-.42.02-.66.31-.71.71.01-.08,0-.02-.02.02-.07.2.04-.05-.02.06-.02.04-.05.08-.07.12,0,.01-.08.11-.05.07.04-.05-.07.08-.09.09-.03.03-.07.07-.1.1s-.07.06-.11.09c.08-.06-.04.03-.06.04-.09.06-.18.12-.28.17-.04.02-.09.04-.13.06.07-.03,0,0-.03.01-.1.04-.2.07-.31.09-.05.01-.09.02-.14.03-.02,0-.18.02-.06.01-.1,0-.19.01-.29.01-.06,0-.12,0-.18,0-.12,0,.09.02-.02,0-.2-.03-.4-.08-.58-.16.09.04-.03-.02-.06-.03-.04-.02-.08-.04-.13-.07-.09-.05-.18-.11-.27-.18.07.05-.1-.09-.13-.12-.04-.04-.08-.08-.12-.13-.01-.01-.11-.13-.05-.05-.23-.3-.61-.46-.97-.25-.3.17-.49.66-.25.97h0Z"
            />
          </svg>
          <div className="recording-time">
            {recordingTime} / {recordingMaxTime / 1000}s
          </div>
        </div>
      )}
    </div>
  );
}

const setNewCoords = (simplex, point, variation, noiseStep) => {
  // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
  const nX = simplex(point.noiseOffsetX, point.noiseOffsetX);
  const nY = simplex(point.noiseOffsetY, point.noiseOffsetY);
  // map this createNoise2D value to a new value, somewhere between it's original location -variation and it's original location + variation
  const x = map(
    nX,
    -1,
    1,
    point.originX - variation,
    point.originX + variation
  );
  const y = map(
    nY,
    -1,
    1,
    point.originY - variation,
    point.originY + variation
  );

  // update the point's current coordinates
  point.x = x;
  point.y = y;

  // progress the point's x, y values through "time"
  point.noiseOffsetX += noiseStep;
  point.noiseOffsetY += noiseStep;
};

export default RecordingInterface;
