import React, { useEffect, useRef, useState } from "react";
import * as Tone from "tone";
import {
  theme_color_mappings,
  notes,
  mapNumRange,
  theme_shape_mapping,
  theme_shape_mapping2,
} from "./constants";

const audioFilePromises = Object.values(notes).map((fileName) => {
  return import(`../../utils/sounds/${fileName}.mp3`);
});

const ExploreTheme = ({ data, containerRef }) => {
  const [audioFiles, setAudioFiles] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [summary, setSummary] = useState(null);
  const [themeCounts, setThemeCounts] = useState({});
  const [uniqueEmotions, setUniqueEmotions] = useState({});
  const [topTwoEmotions, setTopTwoEmotions] = useState({});
  const [topTwoEmotionsPerTheme, setTopTwoEmotionsPerTheme] = useState({});
  const blobWidth = window.innerHeight > window.innerWidth ? "40vw" : "30vw";


  useEffect(() => {
    const loadAudioFiles = async () => {
      const effects = {
        reverb: new Tone.Reverb({
          decay: 1,
          wet: 0.3,
        }).toDestination(),
        delay: new Tone.FeedbackDelay({
          delayTime: 0.5,
          feedback: 0.3,
          wet: 0.2,
        }).toDestination(),
      };

      try {
        const loadedAudioFiles = await Promise.all(audioFilePromises);
        const audioFilesObject = {};

        Object.keys(notes).forEach((emotion, index) => {
          const emotionKey = emotion.replace(/\s+/g, "");
          const player = new Tone.Player(loadedAudioFiles[index].default);
          player.volume.value = 0; // Adjust the volume value to reduce the gain
          player.connect(effects.reverb).connect(effects.delay);
          audioFilesObject[emotionKey] = player;
        });

        setAudioFiles(audioFilesObject);
        setIsLoading(false);
      } catch (error) {
        console.error("Error loading audio files:", error);
        setIsLoading(false);
      }
    };

    loadAudioFiles();
  }, []);

  useEffect(() => {
    if (isLoading) return;

    setThemeCountsAndUniqueEmotions();
  }, [isLoading]);

  useEffect(() => {
    if (Object.keys(themeCounts).length === 0) return;

    const themesArray = Object.entries(themeCounts);
    themesArray.sort((a, b) => b[1] - a[1]);

    const s = themesArray.map(([theme], index) => {
      const separator = index === themesArray.length - 1 ? "" : ", ";
      return `${index > 0 ? " and " : ""}${theme}${separator}`;
    });

    const topTwoThemes = themesArray.slice(0, 2).map(([theme], index) => {
      const separator = index === 1 ? "" : " and ";
      return `${theme}${separator}`;
    });

    setSummary(topTwoThemes);
  }, [themeCounts]);

  const getRandomLimit = () => {
    return Math.floor(Math.random() * (10 - 7 + 1)) + 7;
  };

  const setThemeCountsAndUniqueEmotions = () => {
    const tc = {};
    const ue = {};
    const te = {};
    const tept = {}; //top emotions per theme

    data.forEach((item) => {
      const theme = item.theme;
      const topEmotion = item.top_emotion;

      if (theme) {
        tc[theme] = (tc[theme] || 0) + 1;

        if (topEmotion) {
          if (!ue[theme]) {
            ue[theme] = [];
          }

          const emotionsPerThemeCounts = ue[theme].reduce((acc, emotion) => {
            acc[emotion] = (acc[emotion] || 0) + 1;
            return acc;
          }, {});

          const sortedEmotionsPerTheme = Object.keys(
            emotionsPerThemeCounts
          ).sort(
            (a, b) => emotionsPerThemeCounts[b] - emotionsPerThemeCounts[a]
          );

          tept[theme] = sortedEmotionsPerTheme.slice(0, 2);

          const emotionName = topEmotion.name;

          te[emotionName] = (te[emotionName] || 0) + 1;

          if (!ue[theme].includes(emotionName)) {
            ue[theme].push(emotionName);

            const limit = getRandomLimit();
            if (ue[theme].length > limit) {
              ue[theme] = ue[theme].slice(-limit);
            }
          }
        }
      }
    });

    const sortedEmotionCount = Object.entries(te).sort((a, b) => b[1] - a[1]);
    const topTwoEmotions = sortedEmotionCount
      .slice(0, 2)
      .map(([emotionName]) => emotionName);

    setTopTwoEmotions(topTwoEmotions);
    setThemeCounts(tc);
    setUniqueEmotions(ue);
    setTopTwoEmotionsPerTheme(tept);
  };

  const playEmotionSound = (emotion, duration, time) => {
    const player = audioFiles[emotion];
    if (player) {
      player.start(time);
    }
  };

  const play = (e, theme) => {
    const emotions = uniqueEmotions[theme];
    if (!isLoading) {
      e.target.parentElement.parentElement.classList.add("playing");
      Tone.start();
      const duration = (20 / 100) * 2;
      let currentTime = Tone.Transport.seconds + 0.1;

      emotions.forEach((emotion, index) => {
        Tone.Transport.scheduleOnce((time) => {
          playEmotionSound(emotion, duration, time);
        }, currentTime);
        currentTime += duration;
      });

      // Schedule a callback to stop the animation after the sound has finished playing
      Tone.Transport.scheduleOnce(
        (time) => {
          e.target.parentElement.parentElement.classList.remove("playing");
        },
        emotions.length > 2 ? currentTime : 300
      );

      Tone.Transport.start();
    }
  };

  const displayTopTwoEmotions = (emotionsArray) => {
    if (emotionsArray.length === 0) {
      return "";
    } else if (emotionsArray.length === 1) {
      return (
        emotionsArray[0].charAt(0).toLowerCase() + emotionsArray[0].slice(1)
      );
    } else if (emotionsArray.length === 2) {
      const capitalized = emotionsArray.map(
        (emotion) =>
          emotion.charAt(0).toUpperCase() + emotion.slice(1).toLowerCase()
      );
      return capitalized.join(" and ");
    }
  };

  const getFormattedEmotions = (theme) => {
    const emotions = topTwoEmotionsPerTheme[theme];
    if (!emotions) {
      return { firstEmotion: "", secondEmotion: "" };
    }

    let firstEmotion = "";
    let secondEmotion = "";

    if (emotions.length >= 1) {
      firstEmotion = emotions[0].charAt(0).toLowerCase() + emotions[0].slice(1);
    }

    if (emotions.length >= 2) {
      secondEmotion = emotions
        .slice(1)
        .map((emotion) => emotion.charAt(0).toLowerCase() + emotion.slice(1))
        .join(" and ");
    }

    return { firstEmotion, secondEmotion };
  };

  const Blob = (props) => {
    const { theme, count } = props;
    const totalCount = Object.values(themeCounts).reduce(
      (total, value) => total + value,
      0
    );
    const percentage = (count / totalCount) * 100;

    const maxScaleFactor = 1.5;
    const minScaleFactor = 0.5;

    let scaleFactor;
    if (percentage >= 50) {
      scaleFactor = ((percentage - 50) * (maxScaleFactor - 1)) / 50 + 1;
    } else {
      scaleFactor = (percentage * (1 - minScaleFactor)) / 50 + minScaleFactor;
    }

    const animateRef = useRef();
    const containerRef = useRef();
    const { firstEmotion, secondEmotion } = getFormattedEmotions(theme);

    useEffect(() => {
      const blobContainer = containerRef.current;
      const blobAnimate = animateRef.current;

      const startAnimation = () => {
        blobAnimate.beginElement();
      };
      const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "class" &&
            blobContainer.classList.contains("playing")
          ) {
            startAnimation();
          }
        });
      });
      observer.observe(blobContainer, { attributes: true });
      return () => {
        observer.disconnect();
      };
    }, []);

    return (
      <div
        key={theme}
        id="blob-container"
        style={{ animationDelay: Math.random() * 2 + "s" }}
        ref={containerRef}
      >
        <svg
          key={theme}
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width={blobWidth}
          style={{ transform: `scale(${scaleFactor})` }}
        >
          <path
            onClick={(e) => play(e, theme)}
            className="blob-prop"
            style={{
              animation: `${theme} ${mapNumRange(
                123,
                70,
                200,
                3,
                0.1
              )}s ease infinite`,
            }}
            fill={theme_color_mappings[theme]}
            d={theme_shape_mapping[theme]}
          >
            <animate
              ref={animateRef}
              className="blob-prop"
              attributeName="d"
              values={`${theme_shape_mapping[theme]};${theme_shape_mapping2[theme]};${theme_shape_mapping[theme]}`}
              dur={`${mapNumRange(Math.floor(count), 70, 200, 3, 0.1)}s`}
              begin="no"
            />
          </path>
          <ellipse
            style={{
              animation: `face ${mapNumRange(
                count,
                70,
                200,
                3,
                0.1
              )}s ease infinite`,
            }}
            className="blob-prop"
            cx="45.33"
            cy="75"
            rx=".8"
            ry="1.17"
          />
          <ellipse
            style={{
              animation: `face ${mapNumRange(
                count,
                70,
                200,
                3,
                0.1
              )}s ease infinite`,
            }}
            className="blob-prop"
            cx="51.2"
            cy="75"
            rx=".8"
            ry="1.17"
          />
        </svg>
        <div
          className="blob-text-theme"
          style={{ top: Math.random() * 20 + "%" }}
        >
          <p>
            Hi I'm <b>{theme}</b>!<br /> I'm made up of
            <br /> <b>{count} messages</b>
          </p>
        </div>
        <div className="blob-text-inf">
          {firstEmotion && (
            <p>
              Which mainly express
              <br />
              <b>{secondEmotion ? `${firstEmotion} and` : firstEmotion}</b>
              <br />
              <b>{secondEmotion && secondEmotion}</b>
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="explore-text-div">
        <p>
          Here, we have <b>merged</b> the blobs together by theme.
        </p>
        <p>
          People are sharing a lot about <b>{summary}</b>, and they seem to
          express themselves with a lot of{" "}
          <b>{displayTopTwoEmotions(topTwoEmotions)}</b>.
        </p>
      </div>
      <div className="" id="blob-wrapper" ref={containerRef}>
        {Object.entries(themeCounts).map(([theme, count]) => {
          return <Blob theme={theme} count={count} />;
        })}
      </div>
    </div>
  );
};

export default ExploreTheme;
