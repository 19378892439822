import React, { useEffect, useRef, useState } from "react";
import * as Tone from "tone";

import {
  theme_shape_mapping,
  theme_shape_mapping2,
  emotion_color_mappings,
  notes,
  mapNumRange,
  locationVariations,
} from "./constants";

const audioFilePromises = Object.values(notes).map((fileName) => {
  return import(`../../utils/sounds/${fileName}.mp3`);
});

const ExploreAll = (props) => {
  let {
    sound_file,
    top_emotion,
    bpm,
    meanHz,
    transcript,
    top_emotions,
    theme,
    location,
  } = props.data;
  const source = props.source;
  let count = props.count;

  if (!transcript) transcript = "We could not transcibe what this person said.";
  if (!top_emotion) top_emotion = { name: "Unknown" };
  if (!top_emotions) top_emotions = [];
  if (!theme) theme = "Unknown";
  if (location === undefined) location = "Somewhere";
  const friendlyLocationAnswer = location.split(",")[0];
  const themeAnswer =
    "with " +
    top_emotion.name.charAt(0).toLowerCase() +
    top_emotion.name.slice(1);

  const blobWidth =
    count != undefined
      ? (Math.sqrt((window.innerWidth * window.innerHeight) / count) *
          transcript.length) /
        250
      : "100%";

  const [audioFiles, setAudioFiles] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const blobContainerRef = useRef(null);
  const blobPathRef = useRef(null);
  const blobAnimateRef = useRef(null);

  useEffect(() => {
    const blobContainer = blobContainerRef.current;
    const blobAnimate = blobAnimateRef.current;

    const startAnimation = () => {
      blobAnimate.beginElement();
    };

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class" &&
          blobContainer.classList.contains("playing")
        ) {
          startAnimation();
        }
      });
    });
    observer.observe(blobContainer, { attributes: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const loadAudioFiles = async () => {
      const effects = {
        reverb: new Tone.Reverb({
          decay: 1,
          wet: 0.3,
        }).toDestination(),
        delay: new Tone.FeedbackDelay({
          delayTime: 0.5,
          feedback: 0.3,
          wet: 0.2,
        }).toDestination(),
      };

      try {
        const loadedAudioFiles = await Promise.all(audioFilePromises);
        const audioFilesObject = {};

        Object.keys(notes).forEach((emotion, index) => {
          const emotionKey = emotion.replace(/\s+/g, "");
          const player = new Tone.Player(loadedAudioFiles[index].default);
          player.volume.value = 0; // Adjust the volume value to reduce the gain
          player.connect(effects.reverb).connect(effects.delay);
          audioFilesObject[emotionKey] = player;
        });

        setAudioFiles(audioFilesObject);
        setIsLoading(false);
      } catch (error) {
        console.error("Error loading audio files:", error);
        setIsLoading(false);
      }
    };

    loadAudioFiles();
  }, []);

  const playEmotionSound = (emotion, duration, time) => {
    const player = audioFiles[emotion];
    if (player) {
      player.start(time);
    }
  };

  const play = (e) => {
    if (!isLoading) {
      e.target.parentElement.parentElement.classList.add("playing");
      Tone.start();
      const duration = (20 / bpm) * 2;
      let currentTime = Tone.Transport.seconds + 0.1;

      top_emotions.forEach((emotion, index) => {
        Tone.Transport.scheduleOnce((time) => {
          playEmotionSound(emotion.name, duration, time);
        }, currentTime);
        currentTime += duration;
      });

      // Schedule a callback to stop the animation after the sound has finished playing
      Tone.Transport.scheduleOnce((time) => {
        e.target.parentElement.parentElement.classList.remove("playing");
      }, currentTime);

      Tone.Transport.start();
    }
  };

  return (
    <>
      <div
        id="blob-container"
        ref={blobContainerRef}
        style={{ animationDelay: Math.random() * 2 + "s" }} 
        className={source === "record" ? "w-100" : ""}
      >
        <svg
          width={blobWidth}
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <path
            onClick={play}
            className="blob-prop"
            ref={blobPathRef}
            style={{
              animation: `${theme} ${mapNumRange(
                Math.floor(bpm),
                70,
                200,
                3,
                0.1
              )}s ease infinite`,
            }}
            fill={emotion_color_mappings[top_emotion.name]}
            d={theme_shape_mapping[theme]}
          >
            <animate
              className="blob-prop"
              ref={blobAnimateRef}
              attributeName="d"
              values={`${theme_shape_mapping[theme]};${theme_shape_mapping2[theme]};${theme_shape_mapping[theme]}`}
              dur={`${mapNumRange(Math.floor(bpm), 70, 200, 3, 0.1)}s`}
              begin="no"
            />
          </path>
          <ellipse
            style={{
              animation: `face ${mapNumRange(
                bpm,
                70,
                200,
                3,
                0.1
              )}s ease infinite`,
            }}
            className="blob-prop"
            cx="45.33"
            cy="75"
            rx=".8"
            ry="1.17"
          />
          <ellipse
            style={{
              animation: `face ${mapNumRange(
                bpm,
                70,
                200,
                3,
                0.1
              )}s ease infinite`,
            }}
            className="blob-prop"
            cx="51.2"
            cy="75"
            rx=".8"
            ry="1.17"
          />
        </svg>
        <div className="blob-text-theme" style={{ top: Math.random() * 30 + "%" }}>
          <p>
            {
              locationVariations[
                Math.floor(Math.random() * locationVariations.length)
              ]
            }
            <br />
            from <b>{friendlyLocationAnswer}</b> <br />
            <b>{themeAnswer}</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default ExploreAll;
