import React from "react";

const LandingBlobCluster = () => {
  return (
    <>
      <svg
        version="1.1"
        id="landing-image"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2048 1200"
      >
        <path
          className="cls-1"
          fill="#687755"
          d="M396.9,1202.3c22.1,25.6,51.1,29.7,377.4,16.4c200-8.2,416.5-6.6,432.9-12.8c70-26.4,32.2-91.3-37.6-390.6
	c-39.5-169.4-84.6-331.5-106-331.5c-76.7-0.2-87.2,415.9-116.7,415.9c-44.3,0-28.4-419.3-107.2-412.3
	c-68.8,6.1-97.5,402.3-147.9,401.5c-41.8-0.6-44.2-377.3-118.2-377.3c-28.2,0-70.9,58-169.5,467.5
	C380,1079.7,355.8,1154.5,396.9,1202.3L396.9,1202.3z"
        />
        <path
          className="cls-7"
          fill="#79abcf"
          d="M1566.5,653.6c-52.7-12.2-130.8,11.6-188,38.4c-31.1,14.6-60.4,35.4-94.8,76.9c-22.4,27-32,44-39.8,61.4
	c-5.9,13.1-19.3,46.7-20.4,80.4c-2,60.9,5.9,117.7,28.1,145.2c10.6,13.2,28.4,34.8,86.5,60.2c34.8,15.2,104.3,15.2,153.5,14.4
	c84.1-1.3,147-44.3,170.3-59.6c25.4-16.6,62.9-41.2,88.3-89.7c2.8-5.3,28.7-68.2-4.4-159.2c-12.9-35.5-31.5-75-62.6-103.7
	C1642.9,681.2,1611.2,664,1566.5,653.6L1566.5,653.6z"
        />
        <path
          className="cls-8"
          fill="#79aace"
          d="M102.1,1108c3.6-10.2,17.1-34.7,57.9-56.6c11.5-6.2,23.4-11,35.4-14.8c-6.8-9.7-15.1-18.8-25.9-25
	c-9.8-5.6-24.4-5.3-32.4-7.6c-16.8-4.9-49.2-25.6-47.1-51.9c2.2-26.4,37.6-41.1,54.9-43.6c20.1-2.9,38.2,1.1,76.4,27.3
	c12.6,8.6,23.8,18.4,33.7,29.1c-5.5-17.5-8.8-36-9.3-55.2c-1.2-46.3,7.3-62.2,18.6-79c17-25.3,43.1-34.9,66.4-22.4
	c23.3,12.5,31.4,42.8,18,67.7c-3.9,7.3-10.6,20.1-11.2,31.3c-0.4,7.7,0.6,15.2,2.5,22.3c20.4-15,27.5-19.2,38.2-20.7
	c17.4-2.3,55.4,2.3,64.7,27.1s-4.7,52.9-31.2,62.7c-7.7,2.9-21.2,8-29.1,16.1c-2.3,2.4-4.4,5-6.3,7.6c9.4-2.6,18.7-8.2,24.5-11.7
	c24.1-14.7,54.8-8.4,68.6,14.2c13.8,22.6-2.1,52-15,63.8c-14,12.9-38.9,25.1-76.4,26.5c0.7,0.1,1.3,0.3,2,0.4l0,0
	c13.8,4.3,28.8,7.1,43.9,4.3c11.1-2.1,22.8-10.5,29.5-15.4c22.8-16.6,54-12.8,69.6,8.6c15.6,21.4,0.3,57.7-13.2,68.8
	c-17.7,14.6-29.7,24.1-75.7,29.2c-31.5,3.5-193.2,12.4-224.3,1.8c-24.7-8.4-24.5-41.1-20.2-67.2c-3.3,4.3-6,8.6-8,11.8
	c-15,23.9-45.4,31.9-67.8,17.9C91.6,1161.5,96.2,1124.5,102.1,1108L102.1,1108z"
        />
        <g>
          <path
            className="cls-6"
            fill="#231f20"
            d="M344.2,1102.1c2.5-0.1,4.7,3,4.9,7c0.2,4-1.7,7.3-4.3,7.4c-2.5,0.1-4.7-3-4.9-7
		C339.7,1105.5,341.6,1102.2,344.2,1102.1z"
          />
          <path
            className="cls-6"
            fill="#231f20"
            d="M307.9,1110.9c-0.2-4,1.7-7.3,4.3-7.4c2.5-0.1,4.7,3,4.9,7c0.2,4-1.7,7.3-4.3,7.4
		C310.3,1118,308.1,1114.9,307.9,1110.9z"
          />
        </g>
        <path
          className="cls-5"
          fill=" #df6c50"
          d="M1515,1087.5c-5.1-12.5-23.3-42.2-75.3-67c-14.7-7-29.7-12.4-44.9-16.3c7.9-12.5,17.7-24.2,30.8-32.6
	c11.8-7.6,30-8,39.8-11.3c20.6-7,59.7-34.6,55.5-67.3c-4.2-32.7-49.1-48.9-70.8-51c-25.1-2.4-47.5,3.6-93.5,38.3
	c-15.1,11.4-28.5,24.2-40.2,38.1c5.9-22,8.9-45.2,8.4-69.2c-1.1-57.7-12.6-76.9-27.7-97.2c-22.6-30.5-55.6-41-83.8-24.1
	s-36.6,55.1-18.5,85.2c5.3,8.8,14.4,24.3,15.7,38.3c0.9,9.5,0.1,18.9-1.8,27.9c-26.2-17.5-35.2-22.3-48.7-23.5
	c-21.7-1.9-68.8,6-78.8,37.4c-10.1,31.4,8.9,65.5,42.4,76.2c9.8,3.1,26.9,8.7,37.1,18.3c3,2.8,5.8,5.9,8.3,9.1
	c-11.8-2.7-23.7-9.1-31.1-13.1c-30.8-16.9-68.7-7.3-84.5,21.6c-15.9,28.9,5.6,64.5,22.3,78.5c18.2,15.3,49.8,28.9,96.5,28.5
	c-0.8,0.2-1.6,0.4-2.5,0.6l0-0.1c-16.9,6.1-35.4,10.5-54.3,7.8c-13.9-2-29-11.7-37.5-17.4c-29.4-19.4-67.9-12.8-86,14.7
	c-18.1,27.5,2.9,71.7,20.3,84.8c22.8,17.2,38.3,28.3,95.8,32c39.4,2.5,240.9,4.4,279-10.6c30.2-11.9,28.1-52.5,21.2-84.7
	c4.3,5.1,8,10.3,10.6,14.2c20,28.9,58.2,37.1,85.3,18.3C1531.1,1153.3,1523.2,1107.7,1515,1087.5L1515,1087.5z"
        />
        <g>
          <path
            className="cls-6"
            fill="#231f20"
            d="M1215.8,1039c-1.8-0.3-3.7,1.7-4.3,4.6c-0.5,2.9,0.5,5.5,2.4,5.8s3.7-1.7,4.3-4.6
		C1218.7,1041.9,1217.6,1039.3,1215.8,1039z"
          />
          <path
            className="cls-6"
            fill="#231f20"
            d="M1241.2,1049c0.5-2.9-0.5-5.5-2.4-5.8s-3.7,1.7-4.3,4.6c-0.5,2.9,0.5,5.5,2.4,5.8S1240.7,1051.9,1241.2,1049z"
          />
        </g>
        <path
          className="cls-2"
          fill="#ecbfb0"
          d="M464.4,1229.7c-0.3-0.2-14.8,27.4-20.7,60c-2,10.7-4,22.4,0.2,24.5c6.2,3.2,23.9-16.3,35.2-34.9
	c16.1-26.7,18.9-51.6,20.8-51.2c2.4,0.5,4.2,41.8-0.2,74.2c-0.4,3.1-1.3,9.4,2.1,12.2c5.2,4.3,19.4-0.4,27.2-9.7
	c7.2-8.6,7.5-21.8,7.9-47.7c0.2-11.1-0.3-18.3,0.6-18.4c2.2-0.2,13.5,48.2,16.7,61.8c2.5,10.8,4.2,16.1,10.1,18
	c6.3,2,16.4-0.5,20.5-8.6c8.7-17,9.7-26.5,8-46.2c-0.5-5.4-4.3-19.7-3-19.4c8.7,2,7.5,36.5,22.9,53.8c14.6,16.4,42.4,21.4,49.1,13.3
	c6-7.2-9-22.4-21.7-46.1c-4.5-8.4-9.1-28.8-9.4-28.6c-0.6,0.3,17.5,53.2,44.6,54.9c7.7,0.5,17.8-3.1,21-10.3
	c10.6-23.8-58.6-75.4-54.3-80.7c2.8-3.4,28.7,21.5,61.4,17.3c2.5-0.3,22.8-3.1,24.4-11.7c3.4-18.3-81.6-45.7-78.6-55.3
	c1.8-5.8,34.1,5.2,84.8-3.9c16-2.9,20.6-9.8,21.3-13.1c1.7-9-28.6-26.6-60.8-30.3c-26.3-3-48.7,3.9-49,2.3
	c-0.4-1.9,34.2-4.5,60.4-29c11.7-11,24.2-28.5,19.5-35.5c-5.3-8-31.2,0.4-41.5,3.7c-24.8,8-42.7,20.4-43.4,19.3
	c-0.9-1.3,30.6-16.2,44.6-47.9c1.5-3.3,9.7-21.8,3.9-26.6c-9.5-7.9-52.2,25.4-63.8,34.4c-14.4,11.2-25.7,21.7-27.8,19.9
	c-3.1-2.7,22.8-26,22.1-58c-0.2-10.5-3.4-27.8-11.8-29.9c-12.2-3-33,27.2-41.9,55.4c-4.8,15.3-6.3,30.7-6.6,30.7
	c-0.5-0.1,10.2-34.4-0.4-70.8c-2.3-7.9-9.8-33.5-23.4-34.6c-10.2-0.8-20.6,12.3-25,24c-12.3,32.3,14.4,71.3,12.7,72.1
	c-1.9,0.9-20-55.4-49.4-58.2c-12.4-1.2-26.8,7.1-29.2,17.2c-4.3,18.5,31.8,40.7,28.9,44.7c-2.8,3.9-33.4-22.4-57.3-12.6
	c-6.9,2.8-15.4,9.6-14.9,16.4c1.2,17,57.4,24.9,56.7,28.9c-0.8,4.5-68.9-11.3-78.9,8.7c-1.6,3.1-1.9,7.6,0.1,10.9
	c9.2,15,63.5-3.5,64-1.8c0.3,1.3-27.1,15.7-60.4,26.2c-4.5,1.4-14,4.3-15.6,11c-1.1,4.7,1.8,10.8,6.2,13.6
	c14.2,8.9,46.7-12.1,48.7-9.5c1.3,1.7-13.8,6.2-30.6,16.9c-19.1,12.1-31,15.7-32.9,24.7c-1.2,5.8,3,12.7,8.3,16.3
	c19.5,13.1,60.6-16.2,61.9-14c1.4,2.4-54.4,26.3-53.4,54.1c0.3,8.5,6,18.1,13.7,20.3c21.7,6.3,54.2-45.6,55.5-44.6
	c1.7,1.3-57.8,70.5-46.5,84c4.5,5.4,19.3,0.5,27.9-3.6c9.4-4.4,14.1-15.7,18.6-23C455.3,1242.5,464.6,1229.8,464.4,1229.7
	L464.4,1229.7z"
        />
        <g>
          <path
            className="cls-6"
            fill="#231f20"
            d="M580,1131.8c-1.8,0.4-2.7,3.1-2,5.9s2.7,4.8,4.5,4.4c1.8-0.4,2.7-3.1,2-5.9
		C583.8,1133.3,581.8,1131.3,580,1131.8z"
          />
          <path
            className="cls-6"
            fill="#231f20"
            d="M607.4,1130.7c-0.7-2.8-2.7-4.8-4.5-4.4c-1.8,0.4-2.7,3.1-2,5.9c0.7,2.8,2.7,4.8,4.5,4.4
		C607.1,1136.2,608,1133.5,607.4,1130.7z"
          />
        </g>
        <path
          className="cls-4"
          fill="#8a83ba"
          d="M1691.8,1216.4c-0.4-0.2-18.1,33.5-25.4,73.5c-2.4,13.1-4.9,27.4,0.2,30c7.6,3.9,29.3-19.9,43-42.7
	c19.7-32.7,23.1-63.1,25.4-62.6c2.9,0.6,5.1,51.1-0.3,90.8c-0.5,3.8-1.6,11.5,2.6,15c6.4,5.2,23.8-0.5,33.2-11.9
	c8.8-10.5,9.2-26.6,9.6-58.4c0.2-13.5-0.3-22.4,0.7-22.5c2.7-0.3,16.6,59,20.5,75.7c3.1,13.2,5.1,19.7,12.4,22
	c7.7,2.5,20-0.6,25.1-10.6c10.6-20.7,11.8-32.5,9.7-56.5c-0.6-6.6-5.3-24.1-3.6-23.7c10.7,2.4,9.2,44.7,28,65.9
	c17.9,20.1,51.9,26.1,60.1,16.3c7.4-8.8-11.1-27.5-26.5-56.4c-5.5-10.3-11.1-35.3-11.5-35c-0.7,0.4,21.4,65.1,54.6,67.1
	c9.4,0.6,21.8-3.8,25.7-12.7c12.9-29.2-71.7-92.2-66.4-98.7c3.4-4.2,35.2,26.4,75.1,21.2c3-0.4,27.9-3.8,29.9-14.3
	c4.2-22.4-99.9-55.9-96.2-67.7c2.2-7.1,41.7,6.4,103.8-4.7c19.6-3.5,25.2-12,26-16.1c2.1-11-35-32.5-74.4-37
	c-32.2-3.7-59.5,4.8-60,2.9c-0.5-2.4,41.9-5.5,73.9-35.4c14.4-13.4,29.6-34.9,23.9-43.5c-6.5-9.7-38.2,0.4-50.8,4.5
	c-30.4,9.7-52.3,25-53.2,23.7c-1.1-1.7,37.4-19.8,54.6-58.6c1.8-4.1,11.8-26.7,4.8-32.5c-11.6-9.6-63.8,31.1-78,42.1
	c-17.6,13.7-31.5,26.6-34,24.4c-3.8-3.3,27.9-31.8,27-71c-0.3-12.9-4.2-34-14.4-36.6c-14.9-3.7-40.4,33.3-51.2,67.8
	c-5.9,18.8-7.7,37.6-8.1,37.5c-0.6-0.1,12.4-42.1-0.5-86.6c-2.8-9.7-11.9-41-28.6-42.3c-12.4-1-25.2,15-30.6,29.3
	c-15.1,39.6,17.6,87.2,15.5,88.2c-2.3,1.1-24.5-67.8-60.4-71.3c-15.1-1.5-32.8,8.6-35.7,21c-5.3,22.6,38.9,49.8,35.3,54.8
	c-3.5,4.8-40.9-27.4-70.1-15.4c-8.4,3.5-18.8,11.7-18.2,20.1c1.5,20.8,70.3,30.4,69.4,35.4c-1,5.5-84.4-13.9-96.6,10.6
	c-1.9,3.9-2.4,9.3,0.1,13.3c11.2,18.3,77.7-4.3,78.3-2.1c0.4,1.6-33.2,19.2-73.9,32.1c-5.5,1.8-17.1,5.3-19.1,13.4
	c-1.4,5.8,2.2,13.2,7.6,16.6c17.4,10.9,57.2-14.9,59.6-11.6c1.5,2.1-16.9,7.6-37.5,20.7c-23.4,14.8-38,19.2-40.2,30.2
	c-1.4,7.1,3.7,15.6,10.1,19.9c23.8,16,74.2-19.8,75.7-17.1c1.7,2.9-66.6,32.2-65.4,66.2c0.4,10.4,7.3,22.1,16.8,24.9
	c26.5,7.7,66.3-55.9,68-54.6c2.1,1.6-70.7,86.3-56.8,102.8c5.6,6.6,23.6,0.6,34.1-4.4c11.5-5.4,17.3-19.2,22.8-28.2
	C1680.7,1232,1692.1,1216.5,1691.8,1216.4L1691.8,1216.4z"
        />
        <g>
          <path
            className="cls-6"
            fill="#231f20"
            d="M1849.6,1123.7c2.2,0.5,3.3,3.8,2.5,7.3c-0.8,3.5-3.3,5.9-5.5,5.4s-3.3-3.8-2.5-7.3
		C1845,1125.6,1847.4,1123.2,1849.6,1123.7z"
          />
          <path
            className="cls-6"
            fill="#231f20"
            d="M1816.2,1122.4c0.8-3.5,3.3-5.9,5.5-5.4c2.2,0.5,3.3,3.8,2.5,7.3c-0.8,3.5-3.3,5.9-5.5,5.4
		C1816.5,1129.1,1815.3,1125.9,1816.2,1122.4z"
          />
        </g>
        <path
          className="cls-5"
          fill=" #df6c50"
          d="M161.6,1095.7c-26.1,2.8-38.6,24.9-46.8,40.9c-10.5,20.4-14.4,42.5-15.6,76c-0.6,17.6-2.5,73.3,15,78.1
	c10.4,2.8,24.6-13.2,32.4-27.7c10.9-20.2-2.4-37.7,3.9-40.2c5-2,7,13.8,7.1,20c0.3,23.5,7.8,37.8,15.1,44.7
	c6.2,5.9,25.5-2.7,32.1-13.9c6.8-11.5,6.9-7.9,8.3-30.9c0.8-14.3,2.4-30.8,7.5-30.4c5.6,0.4-8.9,70.5,9.7,72.3
	c37.1,3.6,38.5-9.4,41.5-26.8c4.7-27.2,6.8-54.8,7.1-63.7c0.6-17.3,1.2-36.8-11.3-52.2C247.2,1116.6,197.2,1091.9,161.6,1095.7
	L161.6,1095.7z"
        />
        <g>
          <path
            className="cls-6"
            fill="#231f20"
            d="M154.4,1192.8c-1-0.1-2,1-2.2,2.6c-0.2,1.6,0.4,3,1.4,3.1c1,0.1,2-1,2.2-2.6
		C156,1194.4,155.3,1193,154.4,1192.8z"
          />
          <path
            className="cls-6"
            fill="#231f20"
            d="M168.3,1197.7c0.2-1.6-0.4-3-1.4-3.1c-1-0.1-2,1-2.2,2.6c-0.2,1.6,0.4,3,1.4,3.1S168.1,1199.3,168.3,1197.7z"
          />
        </g>
        <g>
          <path
            className="cls-6"
            fill="#231f20"
            d="M882.4,1010.5c-6.7,0.2-11.9,9.1-11.6,19.8c0.2,10.7,5.9,19.2,12.5,19c6.7-0.2,11.9-9.1,11.6-19.8
		C894.6,1018.7,889,1010.2,882.4,1010.5L882.4,1010.5z"
          />
          <path
            className="cls-6"
            fill="#231f20"
            d="M979.1,1026.5c-0.2-10.7-5.9-19.2-12.5-19c-6.7,0.2-11.9,9.1-11.6,19.8c0.2,10.7,5.9,19.2,12.5,19
		S979.4,1037.2,979.1,1026.5z"
          />
        </g>
        <g>
          <path
            className="cls-6"
            fill="#231f20"
            d="M1597.3,837.4c-3.6,0.6-5.9,5.7-5,11.5c0.8,5.8,4.4,10,8,9.5c3.6-0.6,5.9-5.7,5-11.5
		C1604.5,841,1600.9,836.8,1597.3,837.4z"
          />
          <path
            className="cls-6"
            fill="#231f20"
            d="M1650.9,839.7c-0.8-5.8-4.4-10-8-9.5c-3.6,0.6-5.9,5.7-5,11.5c0.8,5.8,4.4,10,8,9.5S1651.7,845.5,1650.9,839.7
		z"
          />
        </g>
        <path
          className="cls-3"
          fill="#edc0b0"
          d="M1515.8,990.7c-6.8-6.4-3.4-3.6-18-14c-9.1-6.5-25.2-11.4-37.2-11.2c-61.8,0.9-71.8,32.9-69.5,75.6
	c1.4,26.8,20.5,48.4,42,61.1c-32.8,15.6-33.3,45.5-31.8,72.3c2.3,42.7,33.8,71.6,76.4,69.4c10.6-0.6,32.2-4.5,41.6-8
	c9.9-3.8,24.2-16.1,25.1-27.3c8.4,16.3,14.5,28.5,55.2,25.6c21.2-1.5,39.9-9.9,53.1-24.5c13.4-14.7,19.3-28.2,18.2-49.6
	c-2.1-39.5-12.1-58.2-33.6-71c13.2-15.3,19.8-35.6,18.3-62.5c-1.3-24.7-6.6-40.1-27.1-60.7c-11.8-11.8-34.2-14.5-52.1-12.5
	C1548.3,956.3,1523.7,964.6,1515.8,990.7L1515.8,990.7z"
        />
        <g>
          <path
            className="cls-6"
            fill="#231f20"
            d="M1482.2,1131.5c-1.7-0.1-3.2,2.1-3.3,4.8c-0.1,2.7,1.1,4.9,2.8,5c1.7,0.1,3.2-2.1,3.3-4.8
		C1485.2,1133.8,1483.9,1131.6,1482.2,1131.5z"
          />
          <path
            className="cls-6"
            fill="#231f20"
            d="M1506.3,1137.4c0.1-2.7-1.1-4.9-2.8-5c-1.7-0.1-3.2,2.1-3.3,4.8c-0.1,2.7,1.1,4.9,2.8,5
		C1504.7,1142.2,1506.2,1140.1,1506.3,1137.4z"
          />
        </g>
      </svg>
    </>
  );
};

export default LandingBlobCluster;
