import React from 'react';


export const SectionTopper = (props) => {
    const color = props.color;  

    return (
        <svg 
            style={{ bottom:"100%"}} 
            className="section-transition" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 1289.77 425.56"
        >
            <path 
                fill={color} 
                d="m0,404.67c158.99-54.39,174.1-121.61,229-181.44,53.41-58.2,112.67-119.05,178.42-163.3C434.75,41.54,569.39-9.51,697.72,0c171.58,12.72,261.45,115.16,321.74,138.35,91.48,35.18,141.18,24.45,207.46,51.62,31.64,12.97,59.22,37.73,62.85,67.95v167.64H0v-20.89Z"
            />
        </svg> 
    );
};


export const SectionFooter = (props) => {
    const color = props.color;  

    return (
        <svg 
            style={{ top:"100%"}} 
            className="section-transition"
            viewBox="0 0 1289.77 428.83">
            <path 
                fill={color}
                d="m1289.77,63.84V0H0v20.89c158.99,54.39,188.73,104.61,265.26,131.76,100.15,35.54,253.42,41.95,312.02,84.14,26.73,19.25,103.05,146.24,231.38,136.73,171.58-12.72,212.69-164.77,298-210.35"/>
        </svg>
        
    );
};
