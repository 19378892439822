import React from "react";
import Sketch from "react-p5";

import contemplationSound from "../../utils/sounds/1.mp3";
import distressSound from "../../utils/sounds/22.mp3";
import interestSound from "../../utils/sounds/25.mp3";
import concentrationSound from "../../utils/sounds/12.mp3";
import aestheticSound from "../../utils/sounds/6.mp3";
import excitementSound from "../../utils/sounds/21.mp3";
import determinationSound from "../../utils/sounds/19.mp3";
import calmnessSound from "../../utils/sounds/3.mp3";
import disappointmentSound from "../../utils/sounds/20.mp3";
import loveSound from "../../utils/sounds/4.mp3";
import satisfactionSound from "../../utils/sounds/2.mp3";
import boredomSound from "../../utils/sounds/12.mp3";
import fearSound from "../../utils/sounds/22.mp3";
import aweSound from "../../utils/sounds/10.mp3";

let currentIndex = 0;
let increase = true;
let fadeFactor = 3;
let emotionSounds = [];
let circles;
let emotion;

const MySketch = (props) => {

  const { shouldPlay } = props;
  var inView = false;

  const toggleMute = (e) => {
    //let audioFiles = document.getElementsByClassName('emotion-audio');
    emotionSounds.forEach((file) => {
      file.muted = !file.muted;
      // console.log(file);
    });
  };

  const preload = (p) => {};

  const setup = (p, canvasParentRef) => {
    if(!canvasParentRef) return;
    console.log(canvasParentRef);
    p.createCanvas(1000, 700).parent(canvasParentRef);
    //emotion = new Audio(aweSound);
    //console.log(emotion)

    circles = [
      {
        x: 500,
        y: 500,
        size: 50,
        color: "#8A83BA",
        alpha: 0.0,
        sound: new Audio(contemplationSound),
        emotion: "Contemplation",
      },
      {
        x: 300,
        y: 600,
        size: 20,
        color: "#DF6C50",
        alpha: 0.0,
        sound: new Audio(distressSound),
        emotion: "Distress",
      },
      {
        x: 800,
        y: 100,
        size: 30,
        color: "#7AACD0",
        alpha: 0.0,
        sound: new Audio(interestSound),
        emotion: "Interest",
      },
      {
        x: 500,
        y: 300,
        size: 60,
        color: "#687754",
        alpha: 0.0,
        sound: new Audio(concentrationSound),
        emotion: "Concentration",
      },
      {
        x: 300,
        y: 600,
        size: 30,
        color: "#DF6C50",
        alpha: 0.0,
        sound: new Audio(aestheticSound),
        emotion: "Aesthetic Appreciation",
      },
      {
        x: 750,
        y: 150,
        size: 40,
        color: "#D5AD88",
        alpha: 0.0,
        sound: new Audio(excitementSound),
        emotion: "Excitement",
      },
      {
        x: 150,
        y: 500,
        size: 50,
        color: "#EFC2B0",
        alpha: 0.0,
        sound: new Audio(determinationSound),
        emotion: "Determination",
      },
      {
        x: 150,
        y: 100,
        size: 80,
        color: "#DF6C50",
        alpha: 0.0,
        sound: new Audio(calmnessSound),
        emotion: "Calmness",
      },
      {
        x: 500,
        y: 350,
        size: 60,
        color: "#687754",
        alpha: 0.0,
        sound: new Audio(disappointmentSound),
        emotion: "Disappointment",
      },
      {
        x: 300,
        y: 620,
        size: 50,
        color: "#8A83BA",
        alpha: 0.0,
        sound: new Audio(loveSound),
        emotion: "Love",
      },
      {
        x: 800,
        y: 500,
        size: 30,
        color: "#7AACD0",
        alpha: 0.0,
        sound: new Audio(satisfactionSound),
        emotion: "Satisfaction",
      },
      {
        x: 300,
        y: 550,
        size: 80,
        color: "#DF6C50",
        alpha: 0.0,
        sound: new Audio(boredomSound),
        emotion: "Boredom",
      },
      {
        x: 100,
        y: 150,
        size: 20,
        color: "#687754",
        alpha: 0.0,
        sound: new Audio(fearSound),
        emotion: "Fear",
      },
      {
        x: 800,
        y: 600,
        size: 40,
        color: "#687754",
        alpha: 0.0,
        sound: new Audio(aweSound),
        emotion: "Awe",
      },
      // Add more circles with their positions, sizes, and colors
      // ..
    ];
    circles.forEach((circle) => emotionSounds.push(circle.sound));
    toggleMute();
  };

  const draw = (p) => {
    p.background(p.color("#F2E9E0"));
    inView = document.getElementById("p5-section")?.classList.contains("is-inview");

    if(!inView) return;

    const c = circles[currentIndex];

    if (increase && c.alpha < 255) {
      c.alpha += fadeFactor;
    } else {
      increase = false;
      c.alpha -= fadeFactor * 2;
    }

    if (c.alpha < 0 && increase === false) {
      c.sound.play();
      console.log("playing..");
      increase = true;
      currentIndex += 1;
    }

    if (currentIndex > circles.length - 1) {
      currentIndex = 0;
    }

    const circleColor = p.color(c.color);
    circleColor.setAlpha(c.alpha);

    p.fill(circleColor);
    p.noStroke();
    p.ellipse(c.x, c.y, c.size * 2, c.size * 2);
    p.fill(0, 0, 0, c.alpha);
    p.textSize(20);
    //p.textStyle(p.BOLD);
    p.text(c.emotion, c.x + 10, c.y + c.size * 1.5);
  };

  return (
    <div className="flexstyle" style={{flexWrap: "wrap"}}>
      <Sketch preload={preload} setup={setup} draw={draw} />
      <label className="switch">
        <input type="checkbox" className="checkbox-round" onClick={toggleMute}/>
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000" id="sound-icon">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M11.193 4.452L5.67 9H2.376A1.376 1.376 0 0 0 1 10.376v3.248A1.376 1.376 0 0 0 2.376 15h3.293l5.524 4.548a.51.51 0 0 0 .807-.414V4.866a.51.51 0 0 0-.807-.414zM11 18.088L6.028 14H2.375A.375.375 0 0 1 2 13.625v-3.25A.375.375 0 0 1 2.375 10h3.653L11 5.911zm4.47-12.744A9.975 9.975 0 0 1 18 11.987a9.848 9.848 0 0 1-2.566 6.646l-.74-.672A8.872 8.872 0 0 0 17 11.987a8.984 8.984 0 0 0-2.277-5.978zm3.46-3.933A15.817 15.817 0 0 1 23 11.989a15.945 15.945 0 0 1-4.035 10.576l-.735-.65A14.966 14.966 0 0 0 22 11.988a14.852 14.852 0 0 0-3.8-9.924z"></path><path fill="none" d="M0 0h24v24H0z"></path>
          </g>
        </svg>
        <span className="slider round" onClick={toggleMute}></span>
      </label>  
    </div>
  );
}

export default MySketch;
