import React, { useState, useRef, useEffect, useCallback } from "react";
import ExploreAll from "./ExploreAll";
import jsonData from "./transformed_results03.json";
import ExploreTheme from "./ExploreTheme";
import InfoBox from "./InfoBox";

const ExplorePage = (props) => {
  const projectId = props.projectId;
  const useRealData = props.useRealData === "true" ? true : false;

  const [projectRecordings, setProjectRecordings] = useState([]);
  const [isModeAll, setIsModeAll] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    fetch("/api/projects/" + projectId)
      .then((response) => response.json())
      .then((result) => {
        let { projectData, recordings } = result;

        recordings = recordings.map((recording) => ({
          id: recording.id,
          location: recording.location,
          ...JSON.parse(recording.analysis),
        }));

        if (useRealData) {
          const filteredRecordings = recordings.filter(item => 
            !(item.theme === null && item.top_emotion === null && item.transcript === null)
          );
          
          setProjectRecordings(filteredRecordings);
        } else {
          let shuffledStatic = jsonData.results
          .map(value => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);

          setProjectRecordings(shuffledStatic);
        } 
      })
      .catch((error) => {
        console.log(error);
      });

    const intervalId = setInterval(() => {
      const elements = containerRef.current.children;
      if (elements.length === 0) return;

      const randomIndex = Math.floor(Math.random() * elements.length);
      elements[randomIndex].classList.add("playing");

      // Remove the class after 1 second
      setTimeout(() => {
        elements[randomIndex].classList.remove("playing");
      }, 3000);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (containerRef.current && isModeAll){
      shuffleBlobs()
    }
  })

  const toggleTheme = () => {
    setIsModeAll((prevMode) => !prevMode);
  };

  const openPopup = (e) => {
    document.getElementById("info-box").style.visibility = "visible";
  };

  const shuffleBlobs = () => {
    var container = document.getElementsByClassName("allBlobs")[0]
    if (!container.children.length > 0) {return}
    for (var i = container.children.length; i >= 0; i--) {
      container.appendChild(container.children[Math.random() * i | 0]);
    }
  }

  return (
    <div>
      <style>@import url('https://fonts.cdnfonts.com/css/spoof-trial');</style>
      <a href="#" className="popup-switch open" onClick={openPopup}>
        ?
      </a>
      <div className="toggle-container">
        <button
          onClick={() => toggleTheme()}
          type="button"
          className="btn btn-primary plausible-event-name=Clicked+ToggleExploreMode"
        >
          {isModeAll ? "Explore themes" : "Explore all"}
        </button>
      </div>
      {isModeAll ? (
        <div>
          {/* {projectRecordings &&
            projectRecordings.map((recording) => {
              return (
                <div>
                  <a href={recording.sound_recording_url}>
                    {recording.location}
                  </a>
                </div>
              );
            })} */}
          <div className="explore-text-div">
            <p>We've collected <b>{projectRecordings.length}</b> stories from around the world!</p>
            <p>Look around, <b>zoom in</b>, and <b>click on</b> the blobs to hear their melodies. Get to know more about the blobs by <b>clicking the question mark</b>.</p>
            <br/>
            <h1><a href="#" className="popup-switch open" onClick={openPopup} style={{position: "static", fontSize: "xxx-large" }}>?</a></h1>
          </div>
          <div className="allBlobs" id="blob-wrapper" ref={containerRef}>
            {projectRecordings.map((data, index) => (
              <ExploreAll
                key={index}
                data={data}
                count={projectRecordings.length}
                source="explore"
              />
            ))}
          </div>
        </div>
      ) : (
        <ExploreTheme data={projectRecordings} containerRef={containerRef} />
      )}
      <div>
        <InfoBox />
      </div>
    </div>
  );
};

export default ExplorePage;
