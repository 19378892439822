import React, { useState, useRef, useEffect, useCallback } from "react";
import gehl_logo from "./../images/Gehl_logo.png";
import kyu_logo from "./../images/kyu_logo.png";

const TermsAndConditions = (props) => {
  return (
    <>
      <div className="f-container d-flex flex-column justify-content-between">
        <div className="page-content">
          <h1 class="mb-4">What We Cherish - Terms and Conditions</h1>
          <p>Last Updated: 20/11-2023</p>
          <p>
            <b>1. Acceptance of Terms.</b>
          </p>
          <p>
            By accessing or using “What We Cherish” (the "App"), you agree to
            comply with and be bound by these Terms and Conditions. If you do
            not agree with any part of these terms, please do not use the App.
          </p>

          <p>
            <b>2. Voice Recording and Storage.</b>
          </p>
          <p>2.1 Consent:</p>
          <p>
            By using the App, you consent to the recording and storage of your
            voice data in the Microsoft Cloud located within European areas. And
            within regulations of the European General Data Protection
            <br />
            Regulation. The voice data is being send to 3rd party Hume.ai, for
            processing, with the specific purpose of using AI technology to try
            and correctly state the mood and analyze the speech patterns of the
            individual who has made a recording using the app.
          </p>

          <p>2.2 Data Security:</p>
          <p>
            We take reasonable measures to secure your voice recordings.
            However, we cannot guarantee the absolute security of your data.
          </p>

          <p>
            <b>3. Data Collection and Use.</b>
          </p>
          <p>3.1 Purpose:</p>
          <p>
            The primary purpose of voice recording is creating this storytelling
            tool to work with ‘softer data’ — exploring how a heart-centered
            connection to people, planet and place can complement the necessary
            quantitative data stories often driving the climate conversation.
          </p>
          <p>3.2 Microsoft Cloud:</p>
          <p>
            Your voice recordings will be stored in the Microsoft Cloud within
            European areas, and you agree to Microsoft's terms and conditions.
          </p>
          <p>
            <b>4. User Responsibilities.</b>
          </p>
          <p>4.1 Accuracy:</p>
          <p>
            You are responsible for the accuracy and authenticity of the
            information provided during the use of the App. Hate speech is not
            allowed to be used in the App, It creates an environment of
            intimidation and exclusion, and in some cases may promote offline
            violence: Illegal Hate speech is defined in EU law(1) as the public
            incitement to violence or hatred on the basis of certain
            characteristics, including race, colour, religion, descent and
            national or ethnic origin.
          </p>
        </div>
        <div className="logo-container">
          <a href="https://www.gehlpeople.com/">
            <img className="logo" src={gehl_logo} />
          </a>
          <a href="https://kyu.house/">
            {" "}
            <img className="logo" src={kyu_logo} />{" "}
          </a>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
