import React, { useState, useRef, useEffect, useCallback } from "react";
import gehl_logo from "./../images/Gehl_logo.png"
import kyu_logo from "./../images/kyu_logo.png"

const AboutPage = (props) => {
  return (
    <>
      <div className="f-container d-flex flex-column justify-content-between">
        <div className="page-content">

          <svg id="about-blob" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path fill="#7AACD0" d="m24.21,13.42c3.12-4.43,10.94-10.79,25.27-10.88,19.63-.13,27.06,10.9,29.9,13.82,2.92,3.02,15.7,20.76,12.31,42.34-2.93,18.64-17.11,29.16-22.38,32.46-10.4,6.5-28.37,12.88-48.64.48-4.87-2.98-12.01-8.27-13.77-22.75-1.28-10.52,4.35-16.75,7.79-27.51,3.01-9.43,3.24-19.02,9.54-27.96Z"/>
          </svg>

          <h1 class="mb-4 large-title" style={{}}>An experiment about softer data, sound, and storytelling.</h1>

          <p style={{marginTop: "40%"}}>
            As part of the Softer Futures workshop held at Kyu House during COP28, Gehl developed a
            digital experiment to reframe the narrative of the future city by
            asking participants what they cherish in their neighborhood today. 
            The experiment is based on spoken language input and uses AI
            algorithms and sound to shape, interpret, and visualize these stories in
            new and softer ways. 
          </p>
          <p>
            The experiment lives on on this website, and we invite everyone
            to share your stories and explore <a href="/explore">our soft universe</a>.
          </p>

          {/*
          <p>
            At Gehl, we're on a mission to create cities where life can thrive.
          </p>
          <p>
            As we dive deep into de-carbonizing urban life, we're experimenting with new ways to keeping people and their experiences at the heart of everything we do. For this year's COP28, we have created a storytelling tool to learn from what people truly love about their daily lives, connections, and surroundings.
          </p>
          <p>
            Join us in this adventure of listening, learning, and storytelling. This experiment helps us work with 'softer' data, feelings, and cherished moments—to inspire how we take climate action to protect what we love.
          </p>
          <p>This tool is a first step in using qualitative data and collective storytelling to help us connect with what we cherish in our everyday lives, relationships and environments to inspire bold climate action for the planet.</p>
          <p>During COP 28, Gehl is hosting a few different events at kyu House in Alserkal on Tuesday December 5th, we would love to see you there. Learn more <a href="https://kyu.house/">here</a>.</p>
          */}

        </div>
        <div className="logo-container">
            <a href="https://www.gehlpeople.com/"><img className="logo" src={gehl_logo} /></a>
            {/*<a href="https://kyu.house/"> <img className="logo" src={kyu_logo}/> </a>*/}
        </div>
      </div>
    </>
  );
};

export default AboutPage;
