import React, { createContext, useState } from "react";

export const RecordContext = createContext();

export default function RecordProvider({ children }) {

  const [testState, setTestState] = useState();

  return (
    <RecordContext.Provider
      value={{
        testState,
        setTestState,
      }}
    >
      {children}
    </RecordContext.Provider>
  );
}
