import React, { useState, useRef, useEffect, useCallback } from "react";
import { interpolate } from "flubber";
import LocomotiveScroll from "locomotive-scroll";
import EmotionWaves from "./Landing/EmotionWaves";
import LandingBlobCluster from "./Landing/LandingBlobCluster";
import EmotionSounds from "./Landing/EmotionSounds";
import SoftShapes from "./Landing/SoftShapes";
import { SectionTopper, SectionFooter } from "./Landing/SoftSectionEdges";
import blobRow1 from "./../images/blob-row1.svg";
import blobRow2 from "./../images/blob-row2.svg";
import blobRow3 from "./../images/blob-row3.svg";
import softShapesImg from "./../images/shapes_bg.svg";
import exploreBtnImg from "./../images/explore-btn.png";
import recordBtnImg from "./../images/record-btn.png";
import blob01 from "./../images/spinning_blob-1.png";
import blob02 from "./../images/spinning_blob-2.png";
import blob03 from "./../images/spinning_blob-3.png";
import gehlLogo from "./../images/Gehl-logo-white.png";
let softBlob = "m88.25,61.93c-.8-2.09-3.73-7.08-12.29-11.38-2.42-1.21-4.9-2.15-7.4-2.86,1.36-2.05,3.02-3.96,5.22-5.31,1.98-1.22,5.01-1.22,6.65-1.75,3.45-1.1,10.03-5.55,9.44-10.99-.59-5.44-7.99-8.28-11.59-8.7-4.17-.49-7.89.44-15.65,6.06-2.55,1.85-4.81,3.93-6.81,6.2,1.05-3.64,1.62-7.48,1.62-11.46,0-9.58-1.85-12.81-4.28-16.23-3.66-5.13-9.1-6.98-13.85-4.27-4.75,2.71-6.25,9.02-3.36,14.1.85,1.48,2.31,4.09,2.49,6.41.12,1.59-.05,3.14-.4,4.63-4.29-3-5.78-3.83-8.01-4.06-3.6-.38-11.44.77-13.21,5.95-1.77,5.18,1.27,10.91,6.8,12.8,1.62.55,4.43,1.54,6.1,3.16.49.48.94,1,1.35,1.54-1.95-.48-3.92-1.58-5.13-2.28-5.06-2.91-11.38-1.43-14.11,3.31-2.73,4.74.73,10.73,3.45,13.11,2.97,2.59,8.18,4.97,15.93,5.05-.14.03-.27.06-.42.09h0c-2.82.96-5.91,1.62-9.05,1.11-2.3-.37-4.77-2.04-6.18-3.01-4.81-3.31-11.23-2.34-14.33,2.17-3.1,4.51.25,11.92,3.1,14.16,3.73,2.92,6.27,4.82,15.81,5.62,6.53.55,39.99,1.51,46.37-.86,5.05-1.88,4.83-8.63,3.8-13.99.7.87,1.29,1.74,1.72,2.39,3.23,4.86,9.55,6.35,14.1,3.32,4.56-3.03,3.39-10.64,2.09-14.02Z";
let hardBlob = "m81.87,67.32q-1.58-2.4-8.72-10.49c-1.79-2.02-4.36-5.04-6.73-8.39,1.36-2.05,3.24-3.66,5.22-5.31s5.4-5.48,6.68-6.63c3.49-3.17,13.62-15.13,13.62-15.13,0,0-9.26,3.14-15.32,5.84-3.83,1.71-4.62,2.3-12.94,4.5-3.04.8-5.04,1.36-9.98,2.24-2.71-4.13-3.74-6.36-5.48-9.94-4.7-9.65-3.83-7.87-6.66-12.54-3.5-5.79-6.45-11.47-6.45-11.47,0,0,1.21,6.83,2.31,13.33.29,1.68,1.69,8.14,1.87,10.46.12,1.59,3.99,13.48,3.63,14.98-4.29-3-13.13-7.69-15.58-8.77-3.31-1.46-19.77-7.42-19.77-7.42,0,0,7.11,9.17,11.15,13.38,2.32,2.42,7.2,7.38,8.86,9.01.49.48.94,1,1.35,1.54-1.95-.48-7.47-.67-8.87-.79-7.15-.6-17.34-1-17.34-1,0,0,12.87,6.45,16.18,7.92,4.9,2.18,7.17,3.09,16.3,6.86-.14.03-9.74,6.18-12.94,7.58-2.83,1.24-7.39,3.81-10.72,5.22-5.04,2.14-11.54,6.05-11.54,6.05,0,0,10.72-1.21,14.29-1.83,6.11-1.07,33.94-5.25,33.94-5.25l5.78,11.07s4.57-11.62,6.54-17.36c.7.87,15.4,9.08,16.08,9.47,8.24,4.78,17.52,9.55,17.52,9.55,0,0-10.28-13.66-12.27-16.69Z";
var interpolator = interpolate(hardBlob, softBlob, { maxSegmentLength: 0.1 });


function mapValue(value, inMin, inMax, outMin, outMax) {
  const clampedValue = Math.min(Math.max(value, inMin), inMax);
  const mappedValue =
    ((clampedValue - inMin) / (inMax - inMin)) * (outMax - outMin) + outMin;

  return mappedValue;
}

const LandingPage = (props) => {
  useEffect(() => {
    const scroll = new LocomotiveScroll({
      smooth: true,
    });

    const horizontalBoxes = Array.from(
      document.getElementsByClassName("horizontal-box")
    );

    const landingImage = document.getElementById("landing-image");

    let path = document.getElementById("line");
    let pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength + " " + pathLength;
    path.style.strokeDashoffset = pathLength;

    let waves = ["wave-1", "wave-2", "wave-3", "wave-4", "wave-5"];
    let wavesText = [
      "wave-text-1",
      "wave-text-2",
      "wave-text-3",
      "wave-text-4",
      "wave-text-5",
    ];

    if (window.innerWidth < 700) {
      document
        .getElementById("emotion-waves")
        .setAttribute("viewBox", "120 0 620 327.53");
    }

    window.addEventListener("scrollLogger", (e) => {
      // console.log(e.detail);
    });

    window.addEventListener("scrollEvent", (e) => {
      const { target, way } = e.detail;
      if (way === "enter") {
        target.classList.add("animate");
      } else {
        target.classList.remove("animate");
      }
    });

    window.addEventListener("progressEvent", (e) => {
      const { progress, target } = e.detail;

      if (progress <= 0) return;
      let move = progress * 200 * -1 + 100;
      target.style.left = move + "%";

      horizontalBoxes.forEach((elem, i) => {
        elem.style.transform = `rotate(${progress * 360 - i * 4}deg)`;
      });
    });

    window.addEventListener("curveTextProgress", (e) => {
      const { progress } = e.detail;
      var textPath = document.getElementById("text-path-01");
      var mappedProgress = mapValue(progress, 0.25, 0.56, -1, 1);
      textPath.setAttribute("startOffset", 100 * mappedProgress + "%");

      var textPath02 = document.getElementById("text-path-02");
      mappedProgress = mapValue(progress, 0.3, 0.7, 1, -1);
      textPath02.setAttribute("startOffset", 100 * mappedProgress + "%");
      
      var textPath03 = document.getElementById("text-path-03");
      mappedProgress = mapValue(progress, 0.4, 0.8, -1, 1);
      textPath03.setAttribute("startOffset", 100 * mappedProgress + "%");
    });

    window.addEventListener("softBoxProgress", (e) => {
      const { progress } = e.detail;
      var mappedProgress = mapValue(progress, 0.4, 0.6, 0, 1);
      document.getElementById('soft-transform').setAttribute('d', interpolator(mappedProgress));
    });

    window.addEventListener("spinProgress", (e) => {
      const { progress, target } = e.detail;
      let blob1 = document.getElementById("blob1");
      let blob2 = document.getElementById("blob2");
      let blob3 = document.getElementById("blob3");
      blob1.style.rotate = 300 * progress + "deg";
      blob2.style.rotate = 300 * progress + "deg";
      blob3.style.rotate = 300 * progress + "deg";
    });

    window.addEventListener("softShapesProgress", (e) => {
      const { progress, target } = e.detail;
      const svgShapes = document.getElementById("soft-shapes");
      var mappedProgress = mapValue(progress, 0.2, 0.45, 0, 1);

      svgShapes.childNodes.forEach((child, idx) => {
        if (child.classList.contains("softShape"))
          if (mappedProgress * 10 > idx + 2) {
            child.classList.add("show");
          } else {
            child.classList.remove("show");
          }
        if (progress > 0.45 && child.classList.contains("svg-text")) {
          child.classList.add("shows");
          document.getElementById("intro-themes").style.opacity = 1;
        } else {
          child.classList.remove("shows");
          document.getElementById("intro-themes").style.opacity = 0.0;
        }
      });
    });

    window.addEventListener("lineProgress", (e) => {
      const { progress, target } = e.detail;
      //console.log(`target: ${target}`, `progress: ${progress}`);
      var mappedProgress = mapValue(progress, 0.2, 0.5, 0, 1);
      var drawLength = pathLength * mappedProgress;
      path.style.strokeDashoffset = pathLength - drawLength;
    });

    window.addEventListener("waveProgress", (e) => {
      const { progress, target } = e.detail;
      var mappedProgress = mapValue(progress, 0.15, 0.7, 0, 1);
      waves.forEach((value, idx) => {
        let wave = document.getElementById(value);
        let waveText = document.getElementById(wavesText[idx]);
        if (wave && mappedProgress > (idx + 1) / 10) {
          console.log("playing wave: " + value);
          waveText.classList.add("showing");
          wave.beginElement();
          wave.removeAttribute("id");
        }
      });
    });

    window.addEventListener("exploreProgress", (e) => {
      const { progress, target } = e.detail;
      var mappedProgress = mapValue(progress, 0.15, 0.7, 0, 1);

      let row1 = document.getElementById("blobRow1");
      let row2 = document.getElementById("blobRow2");
      let row3 = document.getElementById("blobRow3");
      row1.style.left = -50 + 200 * progress + "%";
      row2.style.left = -row2.offsetWidth * mappedProgress + "px";
      row3.style.left = -100 + 50 * progress + "%";
    });

    window.addEventListener("landingProgress", (e) => {
      const { progress, target } = e.detail;
      landingImage.style.width = window.innerWidth * (1 + progress * 2) + "px";
    });

    return () => {
      scroll.destroy();
    };
  }, []);

  return (
    <div id="scrollytelling-container">
      <div
        className="wrapper flexstyle"
        style={{
          backgroundColor: "#F2E9E0",
          height: "300vh",
          maxHeight: "calc(300vh - 50px)",
          contain: "paint",
        }}
        data-scroll
        data-scroll-event-progress="landingProgress"
      >
        <LandingBlobCluster />
        <div
          className="box-section"
          style={{ backgroundColor: "#687755" }}
        ></div>
        <div
          className="center-caption stickyposition top"
          style={{
            padding: "2rem",
          }}
        >
          <h1>What We Cherish</h1>
          <h2 style={{ fontSize: "16px" }}>An experiment about softer data, sound, and storytelling by Gehl R&D</h2>
          <h3
            className="bounce"
            style={{
              marginBottom: "80px",
              marginTop: "20px",
              fontSize: "16px",
            }}
          >
            Scroll <span>down</span> to explore
          </h3>
          {/* <div className="arrow animated bounce"></div> */}
        </div>
      </div>

      <div
        className="wrapper overlap top"  
        style={{
          height: "450vh",
          backgroundColor: "#EFC2B0",
          flexWrap: "wrap",
        }}
      >
        <SectionTopper color="#EFC2B0" />
        <div 
          style={{height: "40%"}} 
          data-scroll
          data-scroll-event-progress="softBoxProgress"
        >
          <div style={{ marginTop: "80px", padding:"10% 0 10%", position: "sticky", top: "0" }}>
            <svg id="soft-blob" class="separator separator--up"  viewBox="0 0 95 100" preserveAspectRatio="none">
          	  <path 
          	  	id="soft-transform"
          	  	d={hardBlob}
                fill="#f7e9de"
          	  />
            </svg>
            <p className="center-caption"  id="reshape-text">
              At Gehl, we want to reshape the narrative of our future cities <br /> to reflect {" "}
              <span className="fw-bold">what we cherish</span> today 
            </p>
          </div>
        </div>
            {/*<span
              className="rotate-letter"
              //style={{ transform: "rotate(0deg)", fontSize:"30px" }}
            >
              r
            </span>
            <span
              className="rotate-letter"
              //style={{ transform: "rotate(-10deg)", fontSize:"35px" }}
            >
              e
            </span>
            <span
              className="rotate-letter"
              //style={{ transform: "rotate(16deg)", fontSize:"54px" }}
            >
              s
            </span>
            <span
              className="rotate-letter"
              //style={{ transform: "rotate(1deg)", fontSize:"61px" }}
            >
              h
            </span>
            <span
              className="rotate-letter"
              //style={{ transform: "rotate(50deg)", fontSize:"73px" }}
            >
              a
            </span>
            <span
              className="rotate-letter"
              //style={{ transform: "rotate(-5deg)", fontSize:"44px" }}
            >
              p
            </span>
            <span
              className="rotate-letter"
              //style={{ transform: "rotate(20deg)", fontSize:"30px"}}
            >
              e
      </span>*/}
          
        <div 
          style={{height:"60%"}} 
          data-scroll
          data-scroll-event-progress="curveTextProgress"
        >
          <div
            className="stickyposition top"
            id="soft-textt"
            style={{
              width: "100%",
            }}
          >
            <div id="soft-text" style={{position: "relative", top:"0"}}>
              <svg width="100%" viewBox="0 0 1289.77 920.1">
                <path
                  id="curve1"
                  fill="transparent"
                  d="m0,808.09c23.01-43.4,66.49-112.19,142.49-173.94,32.3-26.24,137.88-110.49,281.2-118.86,198.58-11.6,210.44,49.53,328.84,63.04,132.4,15.11,228.74-31.22,346.61-160.15,157.49-172.26,125.2-395.82,120.31-426.14"
                />
                <path 
                  id="curve2"
                  fill="transparent"
                  d="m65.67,0c8,42.43,33.86,140.64,63.78,190.62,31.34,52.35,80.25,176.83,265.25,223.22,162.02,40.62,343.52-108.71,536.3-15.94,143.32,68.96,73.48,175.69,208.72,288.44,54.45,45.4,111.16,67.87,150.05,79.72"
                />
                <text fontSize="10vh">
                  <textPath id="text-path-01" href="#curve1">
                    New Interpretations
                  </textPath>
                </text>
                <text fontSize="10vh">
                  <textPath id="text-path-02" href="#curve2">
                    Soft Technology
                  </textPath>
                </text>
                <text fontSize="10vh">
                  <textPath id="text-path-03" href="#curve1">
                    More Utopias
                  </textPath>
                </text>
              </svg>
            </div>
          </div> 
        </div>
        <SectionFooter color="#EFC2B0" />
      </div>

      <div
        className="wrapper flexstyle overlap"
        data-scroll
        data-scroll-event-progress="spinProgress"
        style={{
          height: "350vh",
          backgroundColor: "#F2E9E0",
          flexWrap: "wrap",
        }}
      >
        <div
          className="center-caption stickyposition top"
          style={{
            padding: "40vh 2rem",
            width: "100%",
            zIndex: "0",
          }}
        >
          <img
            src={blob01}
            style={{
              width: "30%",
              position: "absolute",
              top: "-10vw",
              left: "10%",
            }}
            id="blob1"
          />
          <img
            src={blob03}
            style={{ width: "15%", position: "absolute", bottom: "5%" }}
            id="blob2"
          />
          <img
            src={blob02}
            style={{
              width: "10%",
              position: "absolute",
              top: "20%",
              right: "15%",
            }}
            id="blob3"
          />
          <p>
            We are experimenting with how <b>new AI technologies</b><br/> might help us gain deeper insights into qualitative data
          </p>
        </div>
      </div>

      <div
        className="wrapper flexstyle overlap top"
        data-scroll
        data-scroll-event-progress="exploreProgress"
        style={{
          height: "300vh",
          backgroundColor: "#B2D5E9",
          flexWrap: "wrap",
        }}
      >
        <SectionTopper color="#B2D5E9" />
        <div
          className="center-caption stickyposition top"
          style={{
            padding: "40vh 2rem 50vh",
            width: "100%",
            zIndex: "0",
          }}
        >
          <p className="center-caption small" style={{ marginBottom: "80px" }}>
            We are asking people to record a story of what they love about their neighborhood
          </p>
          <p>
            <span className="speech-text" style={{ "--i": 1 }}>
              I think{" "}
            </span>
            <span className="speech-text" style={{ "--i": 1.5 }}>
              {" "}
              about{" "}
            </span>
            <span className="speech-text" style={{ "--i": 4 }}>
              {" "}
              the little{" "}
            </span>
            <span className="speech-text" style={{ "--i": 6 }}>
              {" "}
              park{" "}
            </span>
            <span className="speech-text" style={{ "--i": 7 }}>
              {" "}
              close to my{" "}
            </span>
            <span className="speech-text" style={{ "--i": 10 }}>
              {" "}
              apartment...
            </span>
          </p>
        </div>
        <SectionFooter color="#B2D5E9" />
      </div>

      <div
        className="wrapper flexstyle overlap"
        data-scroll
        data-scroll-event-progress="softShapesProgress"
        style={{
          height: "800vh",
          flexWrap: "wrap",
        }}
      >
        <div
          className="stickyposition top"
          style={{
            width: "100%",
            height: "100vh",
          }}
        >
          <div className="section-caption" style={{ top: "0" }} id="use-ai">
            <h3 className="">
              Each voice recording is visualized through different <b>soft shapes</b>
            </h3>
          </div>
          <SoftShapes />
          <div
            className="section-caption"
            style={{ bottom: "0" }}
            id="intro-themes"
          >
            <h3 className="">
              By using AI algorithms to predict how the story fits into relevant <b>themes</b>
            </h3>
          </div>
        </div>
      </div>

      <div
        className="wrapper flexstyle overlap top"
        data-scroll
        data-scroll-event-progress="lineProgress"
        style={{
          height: "300vh",
          backgroundColor: "#EFC2B0",
          flexWrap: "wrap",
        }}
      >
        <SectionTopper color="#EFC2B0" />
        <div
          className="stickyposition top"
          style={{
            padding: "20vh 2rem 40vh",
            width: "95%",
          }}
        >
          <p>
            The AI also tries to predict the <b>emotional tones</b> of the voice. It's not only about what people say, it's also how they say it
          </p>
          <svg
            fill="none"
            stroke="black"
            strokeWidth="3px"
            width="100%"
            preserveAspectRatio="xMidYMax meet"
            className="svg-line"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 841.89 406.27"
            style={{ margin: "0vh 0" }}
          >
            <path
              id="line"
              d="m0,172.39c4.05-6.06,11.61-14.2,15.12-26.29,8.58-29.51,20.23-80.38,26.45-80.75,11.12-.66,24.06,110.25,32.81,109.62,6.91-.49,15.44-69.5,24-69.27,10.38.29,12.77,93.19,24.47,92.38,12.43-.86,12.97-110,23.86-110.37,7.43-.25,11.37,50.36,21.63,50.71,14.16.48,22.48-95.53,35.05-95.45,19.23.12,30.36,225.09,52.2,225.21,9.17.05,14.65-39.55,24.77-38.88,12.97.87,13.97,66.73,24.39,67.25,16.56.82,29.58-133.05,45.59-132.63,18.93.5,28.37,188.43,41.61,188.05,7.71-.22,11.97-64.02,21.55-63.88,5.85.09,8.58,23.93,13.83,23.71,8.37-.36,8.47-61.25,22.39-63.23,6.87-.98,10.99,13.26,18.44,12.51,14.18-1.42,16.93-54.72,23.05-54.67,8.32.08,11.6,98.55,19.76,98.79,7.61.23,14.31-85.2,23.05-84.96,7.39.2,11.17,61.45,17.78,61.25,3.92-.12,5.4-21.75,9.22-21.73,5.56.02,7.66,45.9,15.67,46.38,5.3.32,8.71-19.54,14.78-19.21,6.83.36,8.74,25.83,14.04,25.86,6.27.04,9.74-35.57,15.52-35.47,5.09.08,6.59,27.75,12.56,28.08,6.76.38,12.57-34.59,18.47-33.99,4.38.44,5.27,20.08,8.76,20.06,2.86-.01,4.3-13.25,6.72-13.16,3.17.11,3.44,22.78,8.07,23.32,3.54.41,5.87-12.49,10.76-12.56,6.15-.08,8.89,20.25,13.45,20.18,7.02-.11,8.61-48.26,16.7-48.95,8.58-.73,16.42,52.74,28.58,52.46,8.59-.19,11.9-27.03,19.18-26.77,10.67.38,13.67,58.43,24.6,58.97,8.37.42,11.32-33.4,24.24-35.46,11.37-1.81,16.29,20.29,28.73,48.4"
            />
          </svg>
        </div>
        <SectionFooter color="#EFC2B0" />
      </div>

      <div
        className="wrapper flexstyle overlap"
        data-scroll
        data-scroll-repeat
        id="p5-section"
        style={{
          height: "500vh",
          backgroundColor: "#F2E9E0",
          flexWrap: "wrap",
        }}
      >
        <div
          className="stickyposition top"
          style={{
            padding: "15vh 2rem",
            width: "95%",
          }}
        >
          <p>
            Each emotional tone is then mapped to individual <b>musical notes</b>
          </p>
          <EmotionSounds />
        </div>
      </div>

      <div
        className="wrapper flexstyle overlap top"
        data-scroll
        data-scroll-event-progress="waveProgress"
        style={{
          height: "600vh",
          backgroundColor: "#D5AD88",
          flexWrap: "wrap",
        }}
      >
        <SectionTopper color="#D5AD88" />
        <div
          className="stickyposition bottom"
          style={{
            padding: "20vh 25px",
            paddingBottom: "0px",
            width: "95%",
            marginBottom: "20vh",
            display: "flex",
            height: "100vh",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <p>
            Creating a <b>personalized symphony</b> based on the emotional tones of the voice recording
          </p>
          <EmotionWaves />
        </div>
        <SectionFooter color="#D5AD88" />
      </div>

      <div
        className="wrapper flexstyle overlap"
        data-scroll
        data-scroll-event-progress="exploreProgress"
        style={{
          height: "700vh",
          backgroundColor: "#F2E9E0",
          flexWrap: "wrap",
        }}
      >
        <div
          className="stickyposition top"
          style={{
            overflow: "hidden",
            width: "100%",
          }}
        >
          <div id="blobRow1" className="blobRow flexstyle">
            <img src={blobRow1} />
            <img src={blobRow3} />
          </div>
          <div
            id="blobRow2"
            className="flexstyle blobRow"
            style={{ display: "inline-flex" }}
          >
            <img src={blobRow2} />
            <div
              style={{
                fontSize: "30vh",
                whiteSpace: "nowrap",
                marginBottom: "-100px",
              }}
            >
              It all comes together in our universe of soft shapes
            </div>
          </div>
          <div id="blobRow3" className="blobRow flexstyle">
            <img src={blobRow3} />
            <img src={blobRow2} />
          </div>
        </div>
      </div>

      <div
        className="wrapper flexstyle btn-container overlap top"
        style={{ backgroundColor: "#B2D5E9" }}
      >
        <SectionTopper color="#B2D5E9" />

        <a href="/record" className="img-button">
          <img src={recordBtnImg} />
          <p>Share your story</p>
        </a>
        <a href="/explore" className="img-button">
          <img src={exploreBtnImg} />
          <p>Explore others'</p>
        </a>
      </div>
      <div className="footer" >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1289.77 214.41">
          <path fill="#B2D5E9" d="m1289.77,214.41V0H0v187.01C137.9,115.74,423.9,57.67,552.56,60.05c170.59,3.16,396.56,53.86,493.85,83.19"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1290.26 258.97" fill="white" style={{overflow:"visible", marginTop:"10%"}}>
          <path id="footer-text-path" fill="none" stroke-dasharray="4"d="m.26,131.15c28.46-17.26,68.51-39.78,118.35-62.24,39.65-17.86,89.6-40.37,151.3-54.72,50.73-11.8,120.18-18.45,213.53-9.66,19.34,1.82,129.14,14.17,219.97,116.96,36.77,41.61,113.31,75.58,199.58,90.13,84,14.17,144.62-6.87,215.68-41.85,51.46-25.33,129.84-39.7,171.35-45.07"/>
          <text fontSize="100px">
            <textPath id="" href="#footer-text-path">
              🌱 Made by Gehl R&D
              <animate attributeName="startOffset" from="-100%" to ="100%" begin="0s" dur="10s" repeatCount="indefinite"/>
            </textPath>
          </text>
        </svg>
          {/*<div className="contents">
              <p>Made by Gehl R&D <br/> <span className="sprout">🌱</span></p>
          </div>*/}
          <p className="copyright">© Gehl People 2024</p>
          <a href="https://www.gehlpeople.com/"><img className="logo" src={gehlLogo}/></a>
      </div>
    </div>
  );
};

export default LandingPage;
