import React, { useState, useEffect, useRef } from 'react';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
  
function LocationQuestion(props) {
  const { setLocationAnswer, huggingFace } = props;

  const inputRef = useRef();

  const geocoderRef = useRef();
  const [geocoderResult, setGeocoderResult] = useState({});

  let blobA = "m90.82,76.17c-.8-2.09-3.73-7.08-12.29-11.38-2.42-1.21-4.9-2.15-7.4-2.86,1.36-2.05,3.02-3.96,5.22-5.31,1.98-1.22,5.01-1.22,6.65-1.75,3.45-1.1,10.03-5.55,9.44-10.99-.59-5.44-7.99-8.28-11.59-8.7-4.17-.49-7.89.44-15.65,6.06-2.55,1.85-4.81,3.93-6.81,6.2,1.05-3.64,1.62-7.48,1.62-11.46,0-9.58-1.85-12.81-4.28-16.23-3.66-5.13-9.1-6.98-13.85-4.27-4.75,2.71-6.25,9.02-3.36,14.1.85,1.48,2.31,4.09,2.49,6.41.12,1.59-.05,3.14-.4,4.63-4.29-3-5.78-3.83-8.01-4.06-3.6-.38-11.44.77-13.21,5.95-1.77,5.18,1.27,10.91,6.8,12.8,1.62.55,4.43,1.54,6.1,3.16.49.48.94,1,1.35,1.54-1.95-.48-3.92-1.58-5.13-2.28-5.06-2.91-11.38-1.43-14.11,3.31-2.73,4.74.73,10.73,3.45,13.11,2.97,2.59,8.18,4.97,15.93,5.05-.14.03-.27.06-.42.09h0c-2.82.96-5.91,1.62-9.05,1.11-2.3-.37-4.77-2.04-6.18-3.01-4.81-3.31-11.23-2.34-14.33,2.17-3.1,4.51.25,11.92,3.1,14.16,3.73,2.92,6.27,4.82,15.81,5.62,6.53.55,39.99,1.51,46.37-.86,5.05-1.88,4.83-8.63,3.8-13.99.7.87,1.29,1.74,1.72,2.39,3.23,4.86,9.55,6.35,14.1,3.32,4.56-3.03,3.39-10.64,2.09-14.02Z";
  let blobB = "m93.4,78.91c-2.6-5.98-6.83-9.96-15.39-14.26-2.42-1.21-4.9-2.15-7.4-2.86,1.36-2.05,2.9-3.24,5.36-4.03,2.6-.84,10.47.69,12.19.62,8.81-.4,10.84-6.69,10.25-12.13-.59-5.44-9.45-8.55-13.05-8.97-4.17-.49-12.92-1.8-20.68,3.82-2.55,1.85-4.81,3.93-6.81,6.2,1.05-3.64,1.62-7.48,1.62-11.46,0-9.58-2.02-12.89-4.45-16.31-3.66-5.13-11.98-4.77-16.73-2.06-4.75,2.71-6.25,9.02-3.36,14.1.85,1.48,3.09,2.95,4.23,5.16.73,1.41,1.26,2.25.91,3.74-4.29-3-11.99-4.01-16.93-3.69-3.66.23-12,3.46-13.78,8.63-1.77,5.18,1.03,9.84,6.47,11.97,4.11,1.61,14.23-.69,15.9.93.49.48.94,1,1.35,1.54-2.24-.08-3.77-.29-6.12-.72-5.75-1.04-11.38-1.43-14.11,3.31-2.73,4.74.73,10.73,3.45,13.11,2.97,2.59,5.35,4.5,13.1,4.58-.14.03-.27.06-.42.09h0c-2.82.96-3.37,1.1-5.36.84-2.42-.32-3.96-1.52-6.46-2.6-5.36-2.32-11.97-1.35-15.07,3.15-3.1,4.51.25,11.92,3.1,14.16,3.73,2.92,5.78,4.06,15.36,4.06,7.46,0,41.6.84,47.98-1.53,5.05-1.88,4.83-8.63,3.8-13.99.7.87,3.46,6.45,3.89,7.1,3.23,4.86,9.55,6.35,14.1,3.32,4.56-3.03,4.48-12.52,3.03-15.85Z";

  async function query(data) {
    const response = await fetch(
      "https://fpa0nvzk7xnjw2i0.eu-west-1.aws.endpoints.huggingface.cloud",
      {
        headers: { 
          "Authorization": "Bearer "+ huggingFace, 
          "Content-Type": "application/json" 
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    return result;
  }

  useEffect(() => {
    geocoderRef.current = new MapboxGeocoder({
      accessToken: 'pk.eyJ1IjoiZ2VobC1kZXZlbG9wZXIiLCJhIjoiY2xwNnZiNWdpMjUycTJrczY3M2VtNm5nOCJ9.KfHRfyakqYnMY5z6vye1dQ',
      // mapboxgl: mapboxgl,
      marker: false,
      placeholder: "Type in a city or country",
      types: "country, place"
    });
    geocoderRef.current.addTo('#location-search-container');
    geocoderRef.current.on("result", (e) => {
      setGeocoderResult(e);
    });
    query({"inputs": "hello", "parameters": {"candidate_labels": ["greeting", "word"]}}).then((response) => {
      console.log(JSON.stringify(response));
    });
    
  }, []);

  const handleNextClick = () => {
    const answer = geocoderResult.result;
    setLocationAnswer(answer ? answer : "");
  }

  return(
    <div id="questions-container">
      <div className="d-flex flex-column page-content">
        <div>
          <h1>Contribute with a recording</h1>
          <p>We ask you to record up to 30 seconds of audio about what you value and your connections to places, people, and nature.</p>
          <p>The recording will be sent to an algorithm that will transcribe it and code it with themes and emotions based on what you said and how you said it. We will then transform this data into a little creature that plays a melody.</p>
        </div>

        <div>
          <p>First of all, <span className="fw-bold">where is home for you?</span></p>
          <svg id="location-blob" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path fill='#8A83BA' d="m90.82,76.17c-.8-2.09-3.73-7.08-12.29-11.38-2.42-1.21-4.9-2.15-7.4-2.86,1.36-2.05,3.02-3.96,5.22-5.31,1.98-1.22,5.01-1.22,6.65-1.75,3.45-1.1,10.03-5.55,9.44-10.99-.59-5.44-7.99-8.28-11.59-8.7-4.17-.49-7.89.44-15.65,6.06-2.55,1.85-4.81,3.93-6.81,6.2,1.05-3.64,1.62-7.48,1.62-11.46,0-9.58-1.85-12.81-4.28-16.23-3.66-5.13-9.1-6.98-13.85-4.27-4.75,2.71-6.25,9.02-3.36,14.1.85,1.48,2.31,4.09,2.49,6.41.12,1.59-.05,3.14-.4,4.63-4.29-3-5.78-3.83-8.01-4.06-3.6-.38-11.44.77-13.21,5.95-1.77,5.18,1.27,10.91,6.8,12.8,1.62.55,4.43,1.54,6.1,3.16.49.48.94,1,1.35,1.54-1.95-.48-3.92-1.58-5.13-2.28-5.06-2.91-11.38-1.43-14.11,3.31-2.73,4.74.73,10.73,3.45,13.11,2.97,2.59,8.18,4.97,15.93,5.05-.14.03-.27.06-.42.09h0c-2.82.96-5.91,1.62-9.05,1.11-2.3-.37-4.77-2.04-6.18-3.01-4.81-3.31-11.23-2.34-14.33,2.17-3.1,4.51.25,11.92,3.1,14.16,3.73,2.92,6.27,4.82,15.81,5.62,6.53.55,39.99,1.51,46.37-.86,5.05-1.88,4.83-8.63,3.8-13.99.7.87,1.29,1.74,1.72,2.39,3.23,4.86,9.55,6.35,14.1,3.32,4.56-3.03,3.39-10.64,2.09-14.02Z">
              <animate
                attributeName='d'
                values={blobA +';'+ blobB +';'+ blobA}
                dur="2s"
                repeatCount="2"
                begin="1s"
              />
            </path>
            <ellipse fill='black' cx="43.99" cy="77.84" rx="1.01" ry="1.6"/>
            <ellipse fill='black' cx="51.06" cy="77.84" rx="1.01" ry="1.6"/>
            <path className='fade-in' fill='black' d="m19.14,21.66c-.37-.95.13-2.16,1.15-2.45,1.4-.4,1.43,1.83,1.2,2.7-.16.6-.47,1.14-.92,1.57s-1.01.82-1.31,1.42c-.78,1.55.43,2.96,1.56,3.9.7.58,1.71-.42,1-1-.49-.4-1.06-.83-1.29-1.45-.25-.67.15-1.08.62-1.49.94-.82,1.62-1.8,1.81-3.06.18-1.18,0-2.79-.98-3.61s-2.46-.41-3.31.38c-.93.85-1.36,2.29-.89,3.48.33.84,1.7.47,1.37-.38h0Z"/>
            <path className='fade-in' fill='black' d="m22.67,32.61c.91,0,.91-1.42,0-1.42s-.91,1.42,0,1.42h0Z"/>
          </svg>
          <div className="my-4 d-block" id="location-search-container" />
          {'result' in geocoderResult && (
            <button className="btn btn-primary plausible-event-name=Clicked+SubmitLocation" title="Submit" onClick={() => handleNextClick()}>
              Next
            </button>
          )}
          
        </div>
      </div>
    </div>
  )
}


export default LocationQuestion;