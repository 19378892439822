import React from "react";

const SoftShapes = () => {
  return (
    <>
      <svg
        id="soft-shapes"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2048 2425.9"
        height="90vh"
        style={{
          maxWidth: "90vw",
          margin:"5%"
        }}
      >
        <path
          className="softShape"
          fill="#7AACD0"
          d="M1231.2,683.7c-30.4-32.9-70.6-46.8-70.6-46.8c-7.8-2.4-43.3-13.7-87-5c-54.9,11.1-88.3,46.7-121.6,81.9
                	c-23,24.2-51.3,55.7-66.7,105.3c-8.7,26.9-19.3,61.7-3.8,94.4c2.9,6,10,35.2,65.3,64c49.1,25.4,98.1,39.2,161.6,36.5
                	c29-1,76.7-2.9,115.2-34.5C1305.7,913.8,1297.9,756,1231.2,683.7L1231.2,683.7z M1149.4,874.3c-22.6,8.8-48.5,3.1-65.4-11.9
                	c-4.2-4.2-18.3-16.6-19.1-37.6c-0.6-20,11.7-33.7,14-35.7c11.4-11.7,25.1-17,34.9-18.9c12.5-2.1,34.8-3,53.9,12
                	c1.1,0.7,24.1,19.8,20.3,48.5C1183.8,860.6,1154.2,872.4,1149.4,874.3L1149.4,874.3z"
        />
        <path
          className="softShape"
          fill="#855C4A"
          d="M1889,1331.5c11.3-5.3,31.2-0.8,51.4-13.9c12.6-8.2,36.3-29,42-44.1c29-77.5-10.1-123.1-64.4-141.1
                	c-34.1-11.3-97.2,34.6-123.5,55.2c-3.5-48.5-53.3-107.4-87.5-118.7c-54.3-17.9-105.8,7.2-123.7,61.5c-4.5,13.5-5.9,46.1-4.4,59.4
                	c4.1,36.9,37.4,51.9,50.9,58.6c-24.4,2.5-74,4-90.2,56.2c-8.4,27.2-9.4,57.9,2.4,81.5c11.9,23.8,41.8,43.2,69,52.2
                	c50.3,16.6,86-9.5,112.4-30.2c8.9,32.3,27.1,76.2,61.2,87.5c31.4,10.4,73.6,32.9,117.7,5.7c19-11.7,32.7-42.2,40.2-65
                	C1958.2,1388.6,1925.2,1350.4,1889,1331.5C1889,1331.5,1889,1331.5,1889,1331.5z"
        />
        <path
          className="softShape"
          fill="#DF6C50"
          d="M611.3,488.7c-2.3-14.2-13.9-50.9-63.6-91.3c-13.9-11.3-28.7-21.4-43.9-29.6c12-11,25.3-21.7,41.5-26.9
                	c14.7-4.9,34.3-0.5,46.2-2c23.7-2.2,73-21.3,77.1-57.9s-40-65.6-62.8-73.4c-26.6-9.3-52-8.9-111,16.9c-19.5,7.9-36.9,18.4-53.2,30.5
                	c11.7-22,21.3-46.5,27.1-72.5c13.9-62.4,6.9-85.9-4.5-111.6c-16.7-38.5-49-58.7-83.5-48s-53.4,49.5-42.6,86.8c3,10.9,9,30,7,45.2
                	c-1.7,10.6-4.5,20.2-9.3,29.3c-23.6-25.7-32.2-33.1-46.1-38.3c-22.8-7.8-75.3-11.3-94.6,19.9s-7.4,72.8,25.7,93.1
                	c9.7,5.6,26.4,16.1,35,29.7c2.5,4,4.4,7.8,6.3,11.6c-12.3-6.2-23-16.1-29.8-22.4c-29-26.3-72.1-25.6-96.5,1s-11,70.6,3.1,90.1
                	c15.7,21.3,46.1,44.4,96,56.2c-0.7-0.1-2.1,0.2-2.7,0.1l0,0c-19.7,2.4-40.7,1.8-60.8-6c-14.4-5.9-28.3-20-36-28.5
                	c-25.3-27.5-68.2-30.9-94.9-6.1c-26.7,24.8-16,77.7-0.4,96.8c19.9,24.2,34,40.4,94.6,59.3c41.4,13.3,258,67.8,303.1,61.5
                	c35.9-5,43.7-49,45-85.5c3.2,6.9,6,12.9,7.6,18.1c13.7,36.5,53.3,54.9,86.9,41.9C611,563.8,614.9,512.7,611.3,488.7L611.3,488.7z"
        />
        <path
          className="softShape"
          fill="#EFC2B0"
          d="M1139.7,1567c-61.3-8.3-101.7,35-129.8,66.8c-35.2,40.4-56.8,88.5-78,164.1c-11.1,39.8-46.8,165.6-9.7,186.2
                	c22.2,12.2,63.3-16.2,89.6-44.8c36-39.7,15.7-87.1,31.5-89.1c12.6-1.7,8.2,35.5,4.6,49.4c-12.2,53.8-3.7,90.3,9.1,110.1
                	c11,16.8,59.8,8,81-13.7c21.9-22.5,20.1-14.3,36.3-65.6c9.8-31.9,22.7-68.7,34.1-65.1c12.9,3.9-60.3,155.4-18.8,169.9
                	c82.6,29,93.1,0.3,109.4-37.8c26.2-59.2,46.3-120.9,51.9-141c11-39.3,23.5-83.3,3.9-124.9C1322.7,1662.6,1222.7,1578.2,1139.7,1567
                	L1139.7,1567z"
        />
        <path
          className="softShape"
          fill="#8A83BA"
          d="M220,2266c-0.6,0.1-10.7,42.4-7.4,88.8c1,15.1,2.4,31.8,8.7,33.2c9.4,2.2,26.8-30.6,35.8-59.6
                	c12.7-41.7,7.6-77,10.5-77.1c3.5-0.3,20.4,55.5,25.2,100.9c0.6,4.6,1.7,13.1,7.1,15.9c8.8,4.2,26.4-7.5,33.7-22.4
                	c6.9-14.2,2.8-32-5.9-67.5c-3.4-15.1-6.5-24.9-5.4-25.2c2.6-1.3,34.9,61.1,44.1,78.2c7.2,14.1,11.3,20.7,20.2,20.7
                	c9,0.6,22-6.3,25.1-19c6.1-26.4,3.9-39.4-5.3-65.3c-2.4-7-12.5-25.1-10.8-25.5c12.9-0.4,23,47.1,49.6,65.5
                	c25.8,17.4,65.1,14.2,71.6,0.7c5.7-12.1-20.1-27.2-45.6-54.8c-9.1-10-22.4-36-23-35.9c-1,0.8,42.2,66.3,79.9,59.4
                	c10.6-2.2,23.3-10.2,25.1-21.3c6-35.8-105.9-82.1-101.7-90.8c2.7-5.4,46.4,19.1,89.7,1.9c3.2-1.4,29.9-12,29.1-24.2
                	c-1.5-26.2-127-34-125.9-47.9c0.2-8.3,48.3-5,114.3-34.7c20.5-9.5,24.7-20.6,24.1-25.2c-1-12.7-48.2-26.3-93.2-19.9
                	c-37.1,5-65,22.3-66.1,20.3c-1.3-2.6,45-17.7,72.2-60.3c12.1-19.1,23-47.4,13.9-55c-10.1-9.2-42.5,11.1-55.2,19.2
                	c-31,19.3-50.8,42.8-52.3,41.5s35.8-32.4,43.8-80.6c1.1-5,5.6-32.8-3.9-37.4c-15.5-7.2-62.3,52.9-74.6,69.1
                	c-16,20.1-27.7,38.6-31.2,36.5c-5.4-2.7,21.7-43.5,9.8-86.4c-3.8-14.4-14.3-36.4-26.2-36.8c-17.7-0.1-35.2,48.2-37.9,89.7
                	c-1.1,22.7,2,43.8,1.5,43.9s1.7-50-25.2-96.1c-6.2-10.2-24.6-42-43.8-38.7c-14.1,2.5-23.8,24-25.7,41.6
                	c-5.7,48.1,44.1,91.8,42.2,93.5c-2.5,1.8-46.2-68.2-87.2-62.2c-17,2.7-34,18.9-33.7,33.6c0.4,26.5,57.7,44.5,54.6,50.6
                	c-2.4,6.5-53.4-19.1-82.5,2.7c-8.4,6.3-17.7,18.2-14.7,27.5c7.7,22.8,86.9,13.8,87.2,19.7c0.5,6.4-97.6,8.4-104.3,39.1
                	c-1.1,5,0,11.2,3.9,14.9c17.5,17.3,85-27,86.3-24.4c1,1.5-31.5,30.7-73.1,56.9c-5.6,3.3-17.9,10.6-17.1,20.4
                	c0.1,7.1,6.1,14.3,13.1,16.1c22.5,7.1,59.3-32.7,63-30.1c2.2,1.8-16.6,13.2-36.1,33.7c-21.6,23.4-37,32.1-36.1,44.9
                	c0.4,8.2,8.4,16.1,16.8,19.2c31.2,10.7,76.7-43.1,79.1-40.8c3.9,3.7-63.5,55-52.4,92.9c3.6,11.5,14.1,22.3,25.5,22.8
                	c31.6,1.2,58-80.7,59.9-80.1c2.6,1.1-54,116.3-33.7,130.5c8,5.6,26.1-6.3,37-14.4c11.1-9.4,13.8-26.1,17.3-37.6
                	C211.7,2286.5,220,2266,220,2266z"
        />
        <path
          className="softShape"
          fill="#687754"
          d="M279.6,1391.1c17.7,12.1,35.7,9.7,230.3-51.2c118.9-37.4,250.1-71.6,258.6-78.1c37.9-27.1,4.3-60.2-85.9-229.4
                	c-51.1-95.9-104.8-185.7-117.8-182.2c-46.1,12.4,15.2,264.7-2.7,269.5c-27,7.3-84.9-248.3-131.5-230.9
                	c-40.5,15.1,6.2,258-23.7,265.5c-25.4,6.2-87.7-220.4-132.6-208.3c-16.9,4.5-33.3,46.3-26.4,309
                	C249.7,1320.2,247.2,1369.1,279.6,1391.1L279.6,1391.1z"
        />
        <text x="500" y="500" transform="rotate(-10)" className="svg-text" fontSize="5vh">
          Nature
        </text>
        <text x="400" y="1000" transform="rotate(10)" className="svg-text" fontSize="5vh">
          Innovation
        </text>
        <text x="1000" y="900" transform="rotate(-10)" className="svg-text"fontSize="5vh">
          Community
        </text>
        <text x="1600" y="1400" transform="rotate(-10)" className="svg-text" fontSize="5vh">
          Culture
        </text>
        <text x="1000" y="2000" transform="rotate(-10)" className="svg-text" fontSize="5vh">
          Neighborhood
        </text>
        <text x="200" y="2000" transform="rotate(-10)" className="svg-text" fontSize="5vh">
          Weather
        </text>
      </svg>
    </>
  );
};

export default SoftShapes;
