import React from "react";
import { createRoot } from "react-dom/client";

import LandingPageOld from "../components/LandingOld";
import LandingPage from "../components/Landing";
import RecordPage from "../components/Record/Record";
import ExplorePage from "../components/Explore/Explore";
import AboutPage from "../components/About";
import TermsAndConditions from "../components/TermsAndConditions";

import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

const mainContainer = document.getElementById("main-container");
const scrollytellingContainer = document.getElementById("scrollytelling-container");
const recordContainer = document.getElementById("record-container");
const exploreContainer = document.getElementById("explore-container");
const aboutContainer = document.getElementById("about-container");
const TermsAndConditionsContainer = document.getElementById("t-and-c-container");

const visualizationContainer = document.getElementById("visualization-container");

if (mainContainer) {
  const root = createRoot(mainContainer); 
  root.render(
    <LandingPage/>
  );
}

if (scrollytellingContainer) {
  const root = createRoot(scrollytellingContainer); 
  root.render(
    <LandingPage/>
  );
}

if (recordContainer) {
  const root = createRoot(recordContainer); 
  root.render(
    <RecordPage
      projectId={recordContainer.dataset.projectId}
      analysisEndpoint={recordContainer.dataset.analysisEndpoint}
      huggingFace={recordContainer.dataset.huggingFace}
    />
  );
}

if (exploreContainer) {
  const root = createRoot(exploreContainer); 
  root.render(
    <ExplorePage
      projectId={exploreContainer.dataset.projectId}
      useRealData={exploreContainer.dataset.useRealData}
    />
  );
}

if (aboutContainer) {
  const root = createRoot(aboutContainer); 
  root.render(
    <AboutPage/>
  );
}

if (TermsAndConditionsContainer) {
  const root = createRoot(TermsAndConditionsContainer); 
  root.render(
    <TermsAndConditions/>
  );
}

 
