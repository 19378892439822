import React from "react";
import nature from "./../../images/blob_icon_nature.svg";
import culture from "./../../images/blob_icon_culture.svg";
import innovation from "./../../images/blob_icon_innovation.svg";
import neighborhood from "./../../images/blob_icon_neighborhood.svg";
import weather from "./../../images/blob_icon_weather.svg";
import community from "./../../images/blob_icon_community.svg";

const InfoBox = () => {
  const closePopup = (e) => {
    document.getElementById("info-box").style.visibility = "hidden";
  };

  return (
    <div className="info-box" id="info-box">
      <div className="info-box-content">
        <a href="#" className="popup-switch close" onClick={closePopup}>
          ×
        </a>
        <h1>
          Each voice message shapes a unique blobbular form, as well as
          how it moves and sounds
        </h1>
        <br />
        <p>The shapes are formed by the themes identified</p>
        <div className="grid-container">
          <div className="grid-item">
            <img src={nature}></img>
            <p>Nature</p>
          </div>
          <div className="grid-item">
            <img src={neighborhood} />
            <p>Neighborhood</p>
          </div>
          <div className="grid-item">
            <img src={weather} />
            <p>Weather</p>
          </div>
          <div className="grid-item">
            <img src={culture} />
            <p>Culture</p>
          </div>
          <div className="grid-item">
            <img src={community} />
            <p>Community</p>
          </div>
          <div className="grid-item">
            <img src={innovation} />
            <p>Innovation</p>
          </div>
        </div>

        <svg
          width="100px"
          height="100px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0" />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M14.3187 2.50498C13.0514 2.35716 11.8489 3.10033 11.4144 4.29989C11.3165 4.57023 11.2821 4.86251 11.266 5.16888C11.2539 5.40001 11.2509 5.67552 11.2503 6L11.25 6.45499C11.25 6.4598 11.25 6.4646 11.25 6.46938V14.5359C10.4003 13.7384 9.25721 13.25 8 13.25C5.37665 13.25 3.25 15.3766 3.25 18C3.25 20.6234 5.37665 22.75 8 22.75C10.6234 22.75 12.75 20.6234 12.75 18V9.21059C12.8548 9.26646 12.9683 9.32316 13.0927 9.38527L15.8002 10.739C16.2185 10.9481 16.5589 11.1183 16.8378 11.2399C17.119 11.3625 17.3958 11.4625 17.6814 11.4958C18.9486 11.6436 20.1511 10.9004 20.5856 9.70089C20.6836 9.43055 20.7179 9.13826 20.7341 8.83189C20.75 8.52806 20.75 8.14752 20.75 7.67988L20.7501 7.59705C20.7502 7.2493 20.7503 6.97726 20.701 6.71946C20.574 6.05585 20.2071 5.46223 19.6704 5.05185C19.4618 4.89242 19.2185 4.77088 18.9074 4.6155L16.1999 3.26179C15.7816 3.05264 15.4412 2.88244 15.1623 2.76086C14.8811 2.63826 14.6043 2.53829 14.3187 2.50498Z"
              fill="#F3F0E6"
            />{" "}
          </g>
        </svg>
        <p>The emotional tone of your voice composes the blobbular symphony</p>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 86.98"
        >
          <path
            className="cls-1"
            d="m90.82,63.16c-.8-2.09-3.73-7.08-12.29-11.38-2.42-1.21-4.9-2.15-7.4-2.86,1.36-2.05,3.02-3.96,5.22-5.31,1.98-1.22,5.01-1.22,6.65-1.75,3.45-1.1,10.03-5.55,9.44-10.99-.59-5.44-7.99-8.28-11.59-8.7-4.17-.49-7.89.44-15.65,6.06-2.55,1.85-4.81,3.93-6.81,6.2,1.05-3.64,1.62-7.48,1.62-11.46,0-9.58-1.85-12.81-4.28-16.23-3.66-5.13-9.1-6.98-13.85-4.27-4.75,2.71-6.25,9.02-3.36,14.1.85,1.48,2.31,4.09,2.49,6.41.12,1.59-.05,3.14-.4,4.63-4.29-3-5.78-3.83-8.01-4.06-3.6-.38-11.44.77-13.21,5.95-1.77,5.18,1.27,10.91,6.8,12.8,1.62.55,4.43,1.54,6.1,3.16.49.48.94,1,1.35,1.54-1.95-.48-3.92-1.58-5.13-2.28-5.06-2.91-11.38-1.43-14.11,3.31-2.73,4.74.73,10.73,3.45,13.11,2.97,2.59,8.18,4.97,15.93,5.05-.14.03-.27.06-.42.09h0c-2.82.96-5.91,1.62-9.05,1.11-2.3-.37-4.77-2.04-6.18-3.01-4.81-3.31-11.23-2.34-14.33,2.17-3.1,4.51.25,11.92,3.1,14.16,3.73,2.92,6.27,4.82,15.81,5.62,6.53.55,39.99,1.51,46.37-.86,5.05-1.88,4.83-8.63,3.8-13.99.7.87,1.29,1.74,1.72,2.39,3.23,4.86,9.55,6.35,14.1,3.32,4.56-3.03,3.39-10.64,2.09-14.02Z"
          />
        </svg>
        <p>They grow bigger as you feed them more words</p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.11 54.38">
          <path
            className="cls-1"
            d="m8.17,32.05c3.23.07,6.43.6,9.67.63,2.36.02,4.92-.16,6.14-2.46.68-1.29,1.11-2.72,1.53-4.11.19-.64.36-1.3.67-1.9.35-.68,1.37-2.09,2.35-1.81,1.36.39.91,4.08.93,5.04.03,2.27.02,4.55-.03,6.82-.08,3.82-.62,7.95.85,11.59,1.11,2.75,4.53,5.5,7,2.45,1.04-1.29,1.38-3.07,1.63-4.66.33-2.05.41-4.13.48-6.2.14-4.51.23-9.03.27-13.54.02-2.3.03-4.6.03-6.9,0-2.12-.21-4.37.29-6.45.27-1.12,1.16-4.2,2.84-3.56.87.33.84,1.31.92,2.1.1.99.12,1.99.24,2.97.2,1.69.31,3.39.52,5.07.18,1.43.36,2.95,1.43,4.02s2.55,1.46,3.97,1.4,2.52-.87,3.36-1.99,1.45-2.48,1.57-3.89c.07-.87-.04-2.03.58-2.75.94-1.08,1.59.05,1.82.96.4,1.59.39,3.39.47,5.03.07,1.49.05,2.98.03,4.48-.06,4.05-.55,8.6.97,12.46.58,1.46,1.59,2.8,3.16,3.26,1.47.43,3.06-.04,3.92-1.35.92-1.4.91-3.24,1.05-4.85.09-1.05.19-2.1.28-3.15.08-.96.05-2.02.33-2.95.42-1.36,1.14-.2,1.38.62.25.87.42,1.72.98,2.46.8,1.05,2.08,1.67,3.39,1.75,3.18.19,4.55-2.63,5.08-5.3.28-1.39.42-3.09,1.49-4.13,1.21-1.17,3.13-1.16,4.71-1.12,2.16.06,4.33.21,6.49.38,1.36.11,1.36-2.02,0-2.13-2.16-.17-4.32-.32-6.49-.38-1.55-.04-3.16.03-4.6.66-3.03,1.34-3.16,4.41-3.89,7.22-.36,1.39-1.13,2.84-2.79,2.66-2.12-.23-1.96-2.18-2.69-3.7-.61-1.29-2.02-2.31-3.48-1.71-1.32.55-1.69,2.04-1.84,3.32-.24,2.14-.38,4.31-.57,6.46-.07.78-.11,1.63-.43,2.36-.49,1.09-1.67,1.11-2.53.37-1.51-1.3-1.67-4.1-1.79-5.92-.14-2.22-.05-4.45-.02-6.68.05-3.21,0-6.4-.38-9.59-.17-1.36-.58-2.95-1.84-3.7s-2.91-.27-3.83.85c-1.16,1.41-.78,3.04-1.21,4.67-.33,1.22-1.28,3.07-2.69,3.2-1.88.17-2.89-.96-3.18-2.68s-.34-3.35-.53-5.03c-.31-2.67.29-7.5-3.42-7.93-3.43-.4-4.82,4.22-5.1,6.82-.23,2.11-.1,4.27-.1,6.38,0,2.3-.01,4.6-.04,6.89-.05,4.49-.13,8.98-.29,13.47-.08,2.11-.19,4.23-.68,6.29-.4,1.69-1.53,4.13-3.38,2.03-2.58-2.93-1.72-8.11-1.63-11.67.05-2.27.07-4.55.05-6.82-.02-1.77.16-3.74-.35-5.46-1.06-3.58-4.95-2.6-6.61-.05-.8,1.22-1.09,2.7-1.53,4.07-.33,1.01-.69,2.66-1.63,3.3s-2.54.55-3.63.54c-1.6-.01-3.21-.16-4.8-.3s-3.24-.29-4.86-.33c-1.37-.03-1.37,2.09,0,2.13h0Z"
          />
        </svg>

        <p>The blobs beat to the rhythm of your voice</p>
      </div>
    </div>
  );
};

export default InfoBox;
