import React from "react";

const EmotionWaves = () => {
  var waveA01 =
    "m115.99,327.53c-15.8.13-40.87,0-52.85-17.21-8.5-12.2-9.8-27.24-9.71-45.8.24-45.53,3.81-67.29,3.53-92.38-.89-79.93,5.26-90.03,10.78-96.69,26.63-32.12,107.78-38.7,125.04-5.41,6.45,12.44,11.21,28.93,11.01,90.92-.05,15.01-.79,33.37,16.13,64.36,35.96,65.86,103.56,101.59,103.56,101.59,0,0-148.65.15-207.5.62Z";
  var waveA02 =
    "m115.99,327.53c-15.8.13-48.46,290.14-60.45,272.93-8.5-12.2-9.8-27.24-9.71-45.8.24-45.53,24.39-65.52,24.11-90.61-.89-79.93,11.65-90.68,17.16-97.34,26.63-32.12,49.75-38.19,67.02-4.9,6.45,12.44,16.54,23.01,16.35,85-.05,15.01,24.94,37.66,41.85,68.64,35.96,65.86,111.16-188.55,111.16-188.55,0,0-148.65.15-207.5.62Z";
  var waveB01 =
    "m163.64,327.2s25.36-13.84,30.19-24.63c6.88-15.36,11.39-38.78,18.34-49.89,16.39-26.22,89.52-31.3,111.31.15,17.32,24.99,14.64,38.13,21.27,47.09,16.52,22.36,46.34,27.59,46.34,27.59l-227.45-.33Z";
  var waveB02 =
    "m163.64,327.2s33.43,97.59,38.27,86.81c6.88-15.36,41.32-35.17,48.26-46.28,16.39-26.22,23.35-29.34,45.14,2.11,17.32,24.99,50.88,32.56,57.51,41.53,16.52,22.36,38.27-83.84,38.27-83.84l-227.45-.33Z";
  var waveC01 =
    "m284.93,329s12.19-2.06,31.34-45.83c8.69-19.87,7.22-44.1,11.5-72.51.95-6.29,3.26-13.99,7.35-23.68,11.22-26.58,91.54-32.74,109-11.29,6.23,7.65,7.31,14.75,15.92,55.92,7.1,33.96,9.94,50.93,16.64,62.66,7.82,13.69,20.39,32.97,20.39,32.97l-212.14,1.74Z";
  var waveC02 =
    "m284.93,329s15.63,175.33,34.78,131.56c8.69-19.87,37.61-25.8,41.89-54.21.95-6.29,1.15-29.19,5.24-38.88,11.22-26.58,11.37-29.32,28.83-7.86,6.23,7.65,5.81,16.05,14.41,57.22,7.1,33.96,63.34,43.11,70.04,54.84,7.82,13.69,16.95-144.42,16.95-144.42l-212.14,1.74Z";
  var waveD01 =
    "m364.82,327.53s45.16-1.77,59.04-19.56c9.86-12.63,11.47-20.28,12.53-25.24,4.56-21.32,5.82-31.42,9.73-37.78,17.82-28.93,54.89-18.6,76.54-19.52,2.28-.1,23.11-.5,27-3.49,7.89-6.08,8.95-19.51,8.97-35.83.01-14.64-2.39-23.08,5.35-38.17,14.52-28.31,94.1-34.7,116.83-7.29,10.05,12.12,12,44.99,11.7,64.67-.98,62.69-2.88,94.08,6.52,105.89,13.36,16.81,40.31,15.53,40.31,15.53l-374.52.77Z";
  var waveD02 =
    "m364.82,327.53s42.25,214.05,56.13,196.26c9.86-12.63,11.47-20.28,12.53-25.24,4.56-21.32,30.26-24.28,37.34-26.66,32.4-10.91,30.69-9.21,54.57-17.05,2.17-.71,31.58-13.72,35.47-16.71,7.89-6.08,12.27-19.5,12.28-35.81.01-14.64,1.47-21.4,9.21-36.49,14.52-28.31,19.9-34.91,42.63-7.5,10.05,12.12,9.52,45.46,9.21,65.14-.98,62.69,52.55,91.77,61.94,103.59,13.36,16.81,43.22-200.29,43.22-200.29l-374.52.77Z";
  var waveE01 =
    "m618.14,327.01s23.4-3.39,33.11-19.04c4.19-6.76,11.24-20.76,11.85-34.06,1.03-22.72.99-32.63,10.66-51.84,8.46-16.8,24.23-23.49,53.05-24.3,41.3-1.15,56.52,2.52,68.75,17.29,9.32,11.25,11.58,40.92,11.22,63.47-.18,11.06.21,22.92-4.46,32.33-7.56,15.24-40.32,16.48-40.32,16.48l-143.86-.34Z";
  var waveE02 =
    "m618.14,327.01s23,143.39,32.71,127.74c4.19-6.76,25.82-15.61,26.42-28.91,1.03-22.72,6.67-38,16.35-57.21,8.46-16.8,18.65-23.91,32.8-24.07,17.88-.2,37.52,4.4,49.75,19.16,9.32,11.25,30.58,39.04,30.22,61.6-.18,11.06.21,22.92-4.46,32.33-7.56,15.24-39.93-130.3-39.93-130.3l-143.86-.34Z";

  return (
    <div>
      <svg
        id="emotion-waves"
        className="emotion-waves"
        data-name="Layer 1"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 841.89 327.53"
      >
        <path
          fill="#783223"
          className="cls-1"
          d={waveA02}
          id="contemplation-wave"
        >
          <animate
            id="wave-1"
            attributeName="d"
            values={waveA02 + ";" + waveA01}
            dur="2s"
            repeatCount="1"
            begin="indefinite"
            fill="freeze"
          />
        </path>

        <path
          fill="#F8B2A3"
          className="cls-1"
          d={waveB02}
          id="disappointment-wave"
        >
          <animate
            id="wave-2"
            attributeName="d"
            values={waveB02 + ";" + waveB01}
            dur="2s"
            begin="indefinite"
            fill="freeze"
          />
        </path>

        <path fill="#FFDACC" className="cls-1" d={waveC02} id="calmness-wave">
          <animate
            id="wave-3"
            attributeName="d"
            values={waveC02 + ";" + waveC01}
            dur="2s"
            begin="indefinite"
            fill="freeze"
          />
        </path>

        <path
          fill="#B44B34"
          className="cls-1"
          d={waveD02}
          id="contemplation2-wave"
        >
          <animate
            id="wave-4"
            attributeName="d"
            values={waveD02 + ";" + waveD01}
            dur="2s"
            begin="indefinite"
            fill="freeze"
          />
        </path>

        <path
          fill="#EFC2B0"
          className="cls-1"
          d={waveE02}
          id="realization-wave"
        >
          <animate
            id="wave-5"
            attributeName="d"
            values={waveE02 + ";" + waveE01}
            dur="2s"
            begin="indefinite"
            fill="freeze"
          />
        </path>
        <text
          id="wave-text-1"
          x="100"
          y="30"
          className="svg-text"
          //style={{ animationDelay: "2s" }}
        >
          Contemplation
        </text>
        <text
          id="wave-text-2"
          x="240"
          y="215"
          className="svg-text"
          //style={{ animationDelay: "2.5s" }}
        >
          Disappointment
        </text>
        <text
          id="wave-text-3"
          x="370"
          y="150"
          className="svg-text"
          //style={{ animationDelay: "3s" }}
        >
          Calmness
        </text>
        <text
          id="wave-text-4"
          x="520"
          y="100"
          className="svg-text"
          //style={{ animationDelay: "3.5s" }}
        >
          Contemplation
        </text>
        <text
          id="wave-text-5"
          x="720"
          y="180"
          className="svg-text"
          //style={{ animationDelay: "4s" }}
        >
          Realization
        </text>
      </svg>
    </div>
  );
};

export default EmotionWaves;
