import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function GrantPermissions(props) {
  const { setPermissions, locationAnswer } = props;
  const [when, setWhen] = useState(false);
  const [hasMicPermission, setHasMicPermission] = useState(false);

  const [error, setError] = useState([]);

  const friendlyLocationAnswer = locationAnswer.place_name.split(',')[0];

  useEffect(() => {
    if(!when) return;

    const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    permissions.then((stream) => {
      setHasMicPermission(true);
      stream.getAudioTracks()[0].enabled = false; // mute audio
      stream.getAudioTracks()[0].stop()
    })
    .catch((err) => {
      setError(error => Array.from(new Set([...error, 'Microphone'])));
      setHasMicPermission(false);
    });
  }, [when]);

  useEffect(() => {
    if(hasMicPermission) setTimeout(() => setPermissions(true), 1500);
  }, [hasMicPermission])

  return(
    <div id="permissions-container">
      <div className="d-flex flex-column page-content">
        <div>
          <p>Thank you, friend from <span className="fw-bold">{friendlyLocationAnswer}</span></p>
          <h1>Now we will need permission to access your microphone</h1>
          <p>We will only record you after you press "Start" on the next page, and the recording will not be heard by anyone except our friendly algorithms.</p>
          <p>For this to work, the browser on your device needs to be able to access your microphone.</p>
        </div>

        <div className="">
          {(when && error.length > 0) && 
          (
            <div>
              <p>You have to enable {error.length === 1 ? error[0] : (error[0] + ' and ' + error[1])} in order to proceed.</p>
              <p className="fw-small">Please go to your phone or browser settings to allow access for this site.</p>
            </div>
          )}
        </div>
  
        <div>
          <button className="btn btn-primary" title="Give access" onClick={() => setWhen(true)}>
            Give access
          </button>
        </div>
        {(when && (!hasMicPermission) && error.length === 0) &&
          (
            <svg className="spinner mt-2" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
          ) 
        }

        <svg id="permissions-blob" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path fill='#EFC2B0' d="m74.74,13.42c-3.12-4.43-10.94-10.79-25.27-10.88-19.63-.13-27.06,10.9-29.9,13.82-2.92,3.02-15.7,20.76-12.31,42.34,2.93,18.64,17.11,29.16,22.38,32.46,10.4,6.5,28.37,12.88,48.64.48,4.87-2.98,12.01-8.27,13.77-22.75,1.28-10.52-4.35-16.75-7.79-27.51-3.01-9.43-3.24-19.02-9.54-27.96Z"/>
            <ellipse fill='black' cx="28.88" cy="27.79" rx=".87" ry="1.37"/>
            <ellipse fill="black" cx="34.94" cy="29.3" rx=".87" ry="1.37"/>
            <path fill='black' d="m29.74,32.59c.85.62,1.86,1.25,2.96.91.18-.06.3-.25.25-.44s-.25-.3-.44-.25c-.89.28-1.73-.34-2.41-.84-.15-.11-.39-.03-.48.13-.1.18-.03.37.13.48h0Z"/>
          </svg>
      </div>
    </div>
  )
}

export default GrantPermissions;